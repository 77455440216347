const images = [
  { src: '/images/gallery/2015-10-01-15.49.41.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/20150530_103749.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/20150721_103029.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/20150827_150116.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/CAM00578.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/CAM00579.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/WP_001547.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/WP_001549.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/WP_001558.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/grec.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/sfondo.jpg', category: 'azienda', caption : ''   },
  { src: '/images/gallery/BIOENERGY_2013_1.jpg', category: 'fiere', caption : ''   },
  { src: '/images/gallery/BIOENERGY_2013_2.jpg', category: 'fiere', caption : ''   },
  { src: '/images/gallery/DRINKTEC-SIMEI_2017.jpg', category: 'fiere', caption : ''   },
  { src: '/images/gallery/DRINKTEC-SIMEI_2017_1.jpg', category: 'fiere', caption : ''   },
  { src: '/images/gallery/SIMEI-2011-2.jpg', category: 'fiere', caption : ''   },
  { src: '/images/gallery/SIMEI-2013-1.jpg', category: 'fiere', caption : ''   },
  { src: '/images/gallery/SIMEI_2015_1.jpg', category: 'fiere' , caption : ''   }
];

export default images;