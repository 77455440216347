import React, { useEffect, useState, useRef, useCallback,  useMemo } from 'react';

import {
 IoVolumeMute, IoVolumeHigh, IoPause, IoPlay, 
} from "react-icons/io5";

import { useTranslation } from 'react-i18next';


const AboutUs = () => {
    const { t } = useTranslation();
    const videoRef = useRef(null);
    const [isMuted, setIsMuted] = useState(true);
    const [isPaused, setIsPaused] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(false);
    const [showPauseIcon, setShowPauseIcon] = useState(false);
  
    const handleVideoClick = () => {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setShowPlayIcon(true);
        setIsPaused(false);
        setTimeout(() => setShowPlayIcon(false), 1000);
      } else {
        videoRef.current.pause();
        setShowPauseIcon(true);
        setIsPaused(true);
        setTimeout(() => setShowPauseIcon(false), 1000);
      }
    };
  
    const handleMuteClick = () => {
      setIsMuted(prevState => !prevState);
      videoRef.current.muted = !videoRef.current.muted;
    };
  
    return (
      <>
        <section className='page--container'>
          {/* <div className='Grec-title-container'>
            <h1>{t('aboutUs.title')}</h1>
            <p>
              {t('aboutUs.introduction', { link: <Link to="/prodotti">{t('aboutUs.industrialAgitators')}</Link> })}
            </p>
          </div> */}
  
          <div className="video-container-about" onClick={handleVideoClick}>
            <video
              className="video video-about"
              autoPlay
              muted={isMuted}
              playsInline
              ref={videoRef}
            >
              <source src="/CorporateGrec.webm" type="video/webm" />
              {t('aboutUs.browserNotSupported')}
            </video>
            <button className="mute-button" onClick={handleMuteClick}>
              {isMuted ? <IoVolumeMute /> : <IoVolumeHigh />}
            </button>
            {showPlayIcon && <IoPlay className="video-icon play-icon" />}
            {showPauseIcon && <IoPause className="video-icon pause-icon" />}
          </div>
        </section>
  
        <div className='Grec-content-container'>
          <h2>{t('aboutUs.ourPhilosophy')}</h2>
          <p>{t('aboutUs.philosophyPart1')}</p>
          <p>{t('aboutUs.philosophyPart2')}</p>
          <div className='img-container'>
          <img src="/GRC2.png" alt={t('aboutUs.imageAlt')} />
        </div>
        </div>
    
    
  
        <div className='Grec-content-container'>
          <p>{t('aboutUs.expertise')}</p>
          <p>{t('aboutUs.selectionPhilosophy')}</p>
         
        </div>
       
        <div className='Grec-content-container'>
        <div className='img-container'>
          <img src="/GRC3.png" alt={t('aboutUs.imageAlt')} />
        </div>
        <br/>
        {/* <div className='img-container'>
          <img src="/GRC1.png" alt={t('aboutUs.imageAlt')} />
        </div> */}
        </div>
  
        <div className='Grec-content-container'>
          <p>{t('aboutUs.approach')}</p>
          <p>{t('aboutUs.collaboration')}</p>
        </div>
  
    
  
        <div className='Grec-content-container'>
          <p>{t('aboutUs.conclusion')}</p>
        </div>
     
        <br /><br />
      </>
    );
  };

  export default AboutUs