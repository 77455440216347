import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Certifications() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleDownload = (fileName) => {
        const link = document.createElement('a');
        link.href = `/certifications/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <section className='page--container mt-fix'>
                <div className='gradient-page'>
                    <div className='Grec-title-container'>
                        <h1>{currentLanguage === 'it' ? 'CERTIFICAZIONI' : 'CERTIFICATIONS'}</h1>

                        {currentLanguage === 'it' ? <>
                        
                        <p>
                            GREC ha acquisito la certificazione ISO 9001.
                            <br/><br/>
                            <img className='certifications__logo' src="/certifications/en_9001.png" alt="ISO 9001 Logo" /><br/><br/>
                            La ISO 9001 affronta la conformità alla norma internazionale ad adesione volontaria relativa alla "Gestione per la Qualità" dei servizi prestati e dei lavori effettuati.
                            <br/><br/>
                            La ISO 9001 pone al centro del sistema di gestione aziendale il cliente e la sua piena soddisfazione; la visione dell'azienda come un insieme di processi posti in stretta relazione tra loro e finalizzati alla costante rispondenza dei servizi e dei lavori a requisiti predeterminati; l'importanza di perseguire il miglioramento continuativo delle prestazioni aziendali. Qualità significa gestire l'efficacia e l'efficienza dei processi aziendali attraverso la conoscenza, la gestione e il monitoraggio dei processi.
                        </p>
                        </> : <>  <p>
                            GREC has acquired ISO 9001 certification.
                            <br/><br/>
                            <img className='certifications__logo' src="/certifications/en_9001.png" alt="ISO 9001 Logo" /><br/><br/>
                            ISO 9001 addresses compliance with the international voluntary standard related to "Quality Management" for services provided and work performed.
                            <br/><br/>
                            ISO 9001 places the customer and their full satisfaction at the center of the company's management system; it views the company as a set of processes that are closely interconnected and aimed at ensuring the constant conformity of services and work to predetermined requirements. The importance of pursuing continuous improvement in the company's performance is also emphasized. Quality means managing the effectiveness and efficiency of company processes through knowledge, management, and monitoring of those processes.
                        </p>
                        </>}


                      
                    </div>

                    <div className='certifications'>
                        <div>
                            <img className='certifications__img-card' src="/certifications/3FNRA02023100Zert_ita.png" alt="Italian Certification" />
                            <button onClick={() => handleDownload('3FNRA02023100Zert_ita.pdf')} className="download-button">
                                {currentLanguage === 'it' ? 'Scarica la Certificazione | ITA' : 'Download Certification | ITA'}
                            </button>
                        </div>

                        <div>
                            <img className='certifications__img-card' src="/certifications/3FNRA02023100Zert_en.png" alt="English Certification" />
                            <button onClick={() => handleDownload('3FNRA02023100Zert_en.pdf')} className="download-button">
                                {currentLanguage === 'it' ? 'Scarica la Certificazione | ENG' : 'Download Certification | ENG'} 
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Certifications;