import React, { useState, useMemo, useEffect } from 'react';
import {
  IoCloseOutline,
  IoExpandOutline,
  IoCloudDownloadOutline,
} from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// // // // // // // // // // // // // // // // // // // // // // // // 
// Download
// // // // // // // // // // // // // // // // // // // // // // // // 

const Download = () => {
  const [download, setDownload] = useState({ italianDocs: [], englishDocs: [] });
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1);   // Total pages
  const perPage = 5; // Number of documents per page

  const handleDocumentClick = (e, link, title) => {
    e.preventDefault();
    if (window.innerWidth > 1024) {
      setSelectedPdf(link);
      setActiveItem(title);
    } else {
      window.open(link, '_blank');
    }
  };

  const closePdfViewer = () => {
    setSelectedPdf(null);
    setActiveItem(null);
  };

  const openFullscreen = () => {
    window.open(selectedPdf, '_blank');
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = selectedPdf;
    link.download = selectedPdf.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchDownload = async (page = 1, allDownloads = []) => {
    try {
      const response = await axios.get(`https://grec-backend.onrender.com/api/manuals?depth=1&page=${page}`);
      console.log('API Response:', response.data);
  
      // Collect all items across pages
      const downloadsList = [...allDownloads, ...response.data.docs];
      const totalPages = response.data.totalPages; // Get total pages from response
  
      // Map the documents with their URLs
      const downloads = downloadsList.map(download => ({
        ...download,
        docUrl: download.file ? `https://grec-backend.onrender.com/api/media/${download.file}` : null
      }));
  
      // Sort all downloads by the 'order' field in ascending order
      const sortedDownloads = downloads.sort((a, b) => a.order - b.order);
  
      // Filter Italian and English docs directly from the API response
      const italianDocs = sortedDownloads.filter(doc => doc.language === 'it');
      const englishDocs = sortedDownloads.filter(doc => doc.language === 'en');
  
      console.log('Italian Docs:', italianDocs);
      console.log('English Docs:', englishDocs);
  
      // Set the state for both languages
      setDownload({ italianDocs, englishDocs });
      setTotalPages(totalPages);
  
      // If there are more pages, fetch the next one
      if (page < totalPages) {
        fetchDownload(page + 1, downloadsList); // Fetch next page
      }
    } catch (error) {
      console.error('Error fetching downloads:', error);
    }
  };
  

  useEffect(() => {
    fetchDownload(); // Start fetching downloads
  }, []);

  // Filter documents based on the search filter (title) for both Italian and English
  const filteredItalianDocs = useMemo(() => 
    download.italianDocs.filter(doc => doc.title.toLowerCase().includes(filter.toLowerCase())),
    [download.italianDocs, filter]
  );

  const filteredEnglishDocs = useMemo(() => 
    download.englishDocs.filter(doc => doc.title.toLowerCase().includes(filter.toLowerCase())),
    [download.englishDocs, filter]
  );

  const DocumentList = ({ docs, title, img }) => (
    <div className="grec-document-list">
      <div className='grec-document-list-title'>
        <img style={{ height: '30px' }} src={img} alt={title} />
        <h2>{title}</h2>
      </div>
      <div className="grec-documents-container">
        {docs.map((document, index) => (
          <a
            key={index}
            className={`grec-document-item ${activeItem === document.title ? 'active' : ''} ${selectedPdf ? 'pdf-open' : ''}`}
            href={document.docUrl}
            onClick={(e) => handleDocumentClick(e, document.docUrl, document.title)}
          >
            {document.title}
          </a>
        ))}
      </div>
    </div>
  );

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <section className='page--container'>
      <div className='Grec-title-container'>
        <h1>Download</h1>
        <p>{currentLanguage === 'en' ? 'Access our manuals and technical brochures for detailed information about our products and mixing solutions.' : 'Accedi ai nostri manuali e brochure tecniche per informazioni dettagliate sui nostri prodotti e soluzioni di miscelazione.'}</p>
        <input 
          type="text" 
          placeholder="Filtra i Documenti..." 
          value={filter} 
          onChange={(e) => setFilter(e.target.value)}
          className="grec-pdf-filter"
        />
      </div>
      <section className={`grec-download-section ${selectedPdf ? 'grec-with-pdf' : ''}`}>
        <div className="grec-document-lists-container">
          <DocumentList docs={filteredItalianDocs} title="Italian Documents" img="/icons/italy-circular.svg" />
          <DocumentList docs={filteredEnglishDocs} title="English Documents" img="/icons/great-britain-circular.svg" />
        </div>
        {selectedPdf && (
          <div className="grec-pdf-viewer">
            <div className="grec-pdf-controls">
              <button onClick={openFullscreen}><IoExpandOutline size="25" /> Full Screen</button>
              <button onClick={downloadPdf}><IoCloudDownloadOutline size="25" />Download</button>
              <button onClick={closePdfViewer}><IoCloseOutline size="25" />Close</button>
            </div>
            <iframe src={selectedPdf} title="PDF Viewer" />
          </div>
        )}
      </section>
    </section>
  );
}

export default Download;
