import { useTranslation } from 'react-i18next';

const GirantiTable = ({ data }) => {
    const { t } = useTranslation();
  
    const headers = [
      'finitura',
      'pianiInclinati',
      'decapaggio',
      'molatura',
      'satinatura',
      'satinatura08',
      'satinatura015',
      'elettrolucidatura'
    ];
  
    return (
      <div className="giranti-table-container">
        <h2>{t('girantiTable.title')}</h2>
        <div className="giranti-table-wrapper">
          <table className="giranti-table">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header}>{t(`girantiTable.headers.${header}`)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{t(`girantiTable.finitura.${row.finitura}`)}</td>
                  <td>{row.pianiInclinati ? "✓" : ""}</td>
                  <td>{row.decapaggio ? "✓" : ""}</td>
                  <td>{row.molatura ? "✓" : ""}</td>
                  <td>{row.satinatura ? "✓" : ""}</td>
                  <td>{row.satinatura08 ? "✓" : ""}</td>
                  <td>{row.satinatura015 ? "✓" : ""}</td>
                  <td>{row.elettrolucidatura ? "✓" : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  export default GirantiTable