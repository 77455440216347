import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import {
  IoMenuOutline,
  IoCloseOutline,
  IoSearch,
} from "react-icons/io5";


import data from "../../noSQL/data"
import documents from '../../noSQL/documents';

import { useTranslation } from 'react-i18next';

function Navbar() {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [isDivVisible, setIsDivVisible] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isSearchClosing, setIsSearchClosing] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const searchInputRef = useRef(null);
  
    const handleFirstButtonClick = () => {
      setIsDivVisible(true);
      setIsClosing(false);
      setIsSearchVisible(false);
    };
  
    const handleSecondButtonClick = () => {
      setIsClosing(true);
      setTimeout(() => {
        setIsDivVisible(false);
      }, 500);
    };
  
    const handleSearchClick = () => {
      if (isSearchVisible) {
        setIsSearchClosing(true);
        setTimeout(() => {
          setIsSearchVisible(false);
          setIsSearchClosing(false);
        }, 500);
      } else {
        setIsSearchVisible(true);
        setIsDivVisible(false);
      }
      setSearchQuery('');
      setSearchResults([]);
    };
  
    useEffect(() => {
      if (isSearchVisible) {
        searchInputRef.current.focus();
      }
    }, [isSearchVisible]);
  
    useEffect(() => {
      if (searchQuery.length > 0) {
        const dataResults = data.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
        const documentResults = documents.filter(doc => doc.title.toLowerCase().includes(searchQuery.toLowerCase()));
        setSearchResults([...dataResults, ...documentResults]);
      } else {
        setSearchResults([]);
      }
    }, [searchQuery]);
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    return (
      <>
        <nav className="Grec--navbar">
          <div>
            <IoMenuOutline className='Grec--navbar--icon' onClick={handleFirstButtonClick} />
          </div>
          <Link onClick={handleSecondButtonClick} to="/">
            <img src="/logo-red.png" alt="Grec logo" />
          </Link>
          <div>
            {/* <img 
              style={{ height: '30px', marginRight: '5px', cursor: 'pointer' }} 
              src="/icons/italy-circular.svg" 
              alt="ita-flags" 
              onClick={() => changeLanguage('it')}
            />
            <img 
              style={{ height: '30px', marginRight: '15px', cursor: 'pointer' }} 
              src="/icons/great-britain-circular.svg" 
              alt="eng-flags" 
              onClick={() => changeLanguage('en')}
            /> */}
            <IoSearch className='Grec--navbar--icon' onClick={handleSearchClick} />
          </div>
        </nav>
        {isSearchVisible && (
          <div className={`search-container ${isSearchClosing ? 'slide-up' : ''}`}>
            <input
              type="text"
              className="search-input"
              placeholder={t('search.placeholder')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              ref={searchInputRef}
            />
            {searchResults.length > 0 && (
              <ul className="search-results">
                {searchResults.map((result, index) => (
                  <li key={index}>
                    {'route' in result ? (
                      <Link to={`/prodotti/${result.route}`} onClick={handleSearchClick}>
                        <p><strong>{t('search.products')}/</strong> <span>{result.title}</span></p>
                      </Link>
                    ) : (
                      <a href={result.link} target="_blank" rel="noopener noreferrer" onClick={handleSearchClick}>
                        <p><strong>{t('search.download')}/</strong> <span>{result.title}</span></p>
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {isDivVisible && (
          <>
            <div className={`Grec--navbar--menu ${isClosing ? 'slide-out' : 'slide-in'}`}>
              <IoCloseOutline className='Grec--navbar--icon' onClick={handleSecondButtonClick} />
              <div>
                <Link onClick={handleSecondButtonClick} to="/">
                  <p className={location.pathname === '/' ? 'Active' : ''}><span>{t('nav.home')}</span></p>
                </Link>
                <Link to="/chi-siamo" onClick={handleSecondButtonClick}>
                  <p className={location.pathname === '/chi-siamo' ? 'Active' : ''}>{t('nav.aboutUs')}</p>
                </Link>
                <Link onClick={handleSecondButtonClick} to="/prodotti">
                  <p className={location.pathname.startsWith('/prodotti') ? 'Active' : ''}>{t('nav.products')}</p>
                </Link>
                <Link onClick={handleSecondButtonClick} to="/download">
                  <p className={location.pathname.startsWith('/download') ? 'Active' : ''}>{t('nav.download')}</p>
                </Link>
                <Link onClick={handleSecondButtonClick} to="/applicazioni">
                  <p className={location.pathname.startsWith('/applicazioni') ? 'Active' : ''}>{t('nav.applications')}</p>
                </Link>
                <Link onClick={handleSecondButtonClick} to="/gallery">
                  <p className={location.pathname.startsWith('/gallery') ? 'Active' : ''}>{t('nav.gallery')}</p>
                </Link>
                <Link onClick={handleSecondButtonClick} to="/contatti">
                  <p className={location.pathname.startsWith('/contatti') ? 'Active' : ''}>{t('nav.contacts')}</p>
                </Link>
                <Link onClick={handleSecondButtonClick} to="/lavora-con-noi">
                  <p className={location.pathname.startsWith('/lavora-con-noi') ? 'Active' : ''}>{t('nav.workWithUs')}</p>
                </Link>
                <Link onClick={handleSecondButtonClick} to="/certifications">
                  <p className={location.pathname.startsWith('/certifications') ? 'Active' : ''}>{t('nav.certifications')}</p>
                </Link>
                  <div className="cont-lang-toggle">
                    <img
                      style={{ height: '30px', marginRight: '5px', marginBottom: '5px', cursor: 'pointer' }}
                      src="/icons/italy-circular.svg"
                      alt="ita-flags"
                      onClick={() => changeLanguage('it')}
                    />
                    <img
                      style={{ height: '30px', marginRight: '15px', marginBottom: '5px', cursor: 'pointer' }}
                      src="/icons/great-britain-circular.svg"
                      alt="eng-flags"
                      onClick={() => changeLanguage('en')}
                    />
                  </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }


  export default Navbar