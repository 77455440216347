

import React, { useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import "@maptiler/sdk/dist/maptiler-sdk.css";
import './scss/styles.scss'
import { initMatomo } from './components/matomo';
import CreatedByFirewall from "./CreatedbyFirewall"
import data from "./noSQL/data"
import Disclaimer from './pages/Disclaimer';
import InfoModal from './components/InfoModal'



// COMPONENTI
import Footer from "./pages/components/Foooter"
import Navbar from "./pages/components/Navbar"
import BottomNavbar from "./pages/components/BottomNavbar"
import ScrollToTop from './pages/components/ScrollToTop';
//PAGINE
import Homepage from "./pages/Homepage"
import AboutUs from "./pages/AboutUs"
import Product from "./pages/Prodotti"
import ProductDetails from "./pages/ProductDetails"
import CategoryProducts from "./pages/CategoryProducts"
import Applicazioni from "./pages/Applicazioni"
import Download from "./pages/Download"
import Gallery from "./pages/Gallery"
import Contatti from "./pages/Contatti"
import Certifications from "./pages/Certifications"
import Lavora from "./pages/Lavora"



const backgroundMap = {
  '/': 'linear-gradient(90deg,  #E44949, #1E1E1E)',
  '/chi-siamo': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/prodotti': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/applicazioni': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/download': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/gallery': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/contatti': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/disclaimer': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/lavora-con-noi': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/agitatori-verticali': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/agitatori-laterali': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/agitatori-portatili': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/in-linea': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/giranti': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/certifications': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
};

function getBackground(pathname) {
  if (pathname.startsWith('/prodotti/')) {
    return backgroundMap['/prodotti'] || 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))';
  }
  return backgroundMap[pathname] || 'linear-gradient(90deg,  #E44949, #1E1E1E)';
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    const background = getBackground(location.pathname);
    document.body.style.background = background;
  }, [location]);

  useEffect(() => {
    initMatomo();
  }, []);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY > lastScrollY) {
        document.body.classList.add('scroll-down');
        document.body.classList.remove('scroll-up');
      } else {
        document.body.classList.add('scroll-up');
        document.body.classList.remove('scroll-down');
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <BottomNavbar/>
      <InfoModal/>
      <div className="body--max--control">
      <CreatedByFirewall/>
        <Routes>
        <Route path="*" element={<Homepage />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/chi-siamo" element={<AboutUs />} />
          <Route path="/prodotti" element={<Product data={data} />} />
          <Route path="/prodotti/:route" element={<ProductDetails />} />
          <Route path="/applicazioni" element={<Applicazioni />} />
          <Route path="/download" element={<Download />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contatti" element={<Contatti />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/certifications" element={<Certifications />} />
          <Route path="/lavora-con-noi" element={<Lavora />} />
          <Route path="/categoria/:category" element={<CategoryProducts />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}



export default App;

