import { useTranslation } from 'react-i18next';
import Map3D2 from "./components/Map3D2";


function Contatti() {
 
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    return (
      <>
        <section className='page--container mt-fix'>
  
        <div className='gradient-page' >
          <div className='Grec-title-container'>
          <h1>{currentLanguage === 'it' ? 'CONTATTI' : 'CONTACTS'}</h1>
           Viale Lombardia, 16/N
  20081 Cassinetta di Lugagnano (MI) - ITALY
  <br/>
   <strong>Tel + 39 02 36645050</strong>
   <span style={{  margin : '0 15px',   }}  > |</span>
   <strong>E-mail: info@grec.it</strong>
   <span style={{  margin : '0 15px',   }}  > |</span>
   <strong>PEC: grec@pec.grec.it</strong>
  <br/>
  Codice univoco SDI: SUBM70N
          </div>
  
          <div className='contact-us--page'>
            <div className='Grec--contact-container'>
          
             <Map3D2/>
        </div>
  </div>
        <br/>
     <br/>
  
       {/* <div>
  
  
  
       <div className='Grec-title-container'>
          <h1>Dove Trovarci</h1>
          <p>
            Viale Lombardia 16/N - 20081 Cassinetta di Lugagnano (MI) - ITALY
          </p>
  
        </div>
  
        <div className='Maps' >
  
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1181.007014473334!2d8.900097095084776!3d45.41890489789257!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786ddb7db02c9b9%3A0xd040a5e59a3ea728!2sGrec!5e1!3m2!1sit!2sit!4v1727339320776!5m2!1sit!2sit"
            style={{ border: 'none', width: '100%', height: '100%' }}
            title="Grec Maps"
          />
        </div> */}
  
  
       </div>
  

       
        </section>
      </>
  
    );
  }

export default Contatti