import React, { useEffect, useState, useRef} from 'react';
import { BrowserRouter as Router, Link} from 'react-router-dom';
import {
  IoArrowForward
} from "react-icons/io5";
import * as maptilersdk from '@maptiler/sdk';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import { motion } from 'framer-motion';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


const Map3D2 = () => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const cassinetta = { lng: 8.899884, lat: 45.419239 };
    const italy = { lng: 12.5674, lat: 41.8719 };
    const startZoom = 5;
    const endZoom = 18;
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    useEffect(() => {
      maptilersdk.config.apiKey = 'p8lNItSZn68a2LUsyvxf';
  
      if (map.current) return;
  
      map.current = new maptilersdk.Map({
        container: mapContainer.current,
        style: maptilersdk.MapStyle.DATAVIZ_DARK,
        center: [italy.lng, italy.lat],
        zoom: startZoom,
        pitch: 0,
        bearing: 0,
      });
  
      map.current.on('load', () => {
        // Aggiungi un punto rosso
        map.current.addLayer({
          id: 'grec-point',
          type: 'circle',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [cassinetta.lng, cassinetta.lat]
              }
            }
          },
          paint: {
            'circle-radius': 12,
            'circle-color': '#E44949',
            'circle-stroke-width': 3,
            'circle-stroke-color': '#FF5656'
          }
        });
  
        map.current.addControl(new maptilersdk.NavigationControl());
  
        map.current.addLayer({
          id: 'grec-label',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [cassinetta.lng, cassinetta.lat]
              },
              properties: {
                title: 'GREC | Viale Lombardia 16/N - 20081 Cassinetta di Lugagnano (MI) - ITALY'
              }
            }
          },
          layout: {
            'text-field': ['get', 'title'],
            'text-font': ['Poppins'],
            'text-size': 17,
   
            'text-offset': [0, -1.2],
            'text-anchor': 'bottom'
          },
          paint: {
            'text-color': '#000000',
     
          }
        });
  
        setTimeout(() => {
          map.current.flyTo({
            center: [cassinetta.lng, cassinetta.lat],
            zoom: endZoom,
            pitch: 60,
            bearing: -60,
            duration: 8000,
            essential: true
          });
        }, 1000);
      });
    }, []);
  
    const openDirections = () => {
      window.open('https://www.google.com/maps/place/Grec/@45.418071,8.900179,16z/data=!4m15!1m8!3m7!1s0x4786efe5b8c37d8b:0xf1a49539bc11915b!2sViale+Lombardia,+16,+20081+Cassinetta+di+Lugagnano+MI!3b1!8m2!3d45.4180711!4d8.9001793!16s%2Fg%2F11c4p88vk9!3m5!1s0x4786ddb7db02c9b9:0xd040a5e59a3ea728!8m2!3d45.4190996!4d8.8997964!16s%2Fg%2F12hp85z0v?hl=it&entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D', '_blank');
    };
  
    return (
      <div className="map-wrap">
        <div ref={mapContainer} className="maplibregl-map" />
        <div className='vitoo'>    <button onClick={openDirections}>
        {currentLanguage === 'en' ? 'Directions' : 'Indicazioni'}
  
          </button></div>
        <motion.div 
          className="info-box"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
        
       <FormContatti/>
        </motion.div>
      </div>
    );
  };

  function FormContatti() {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
      tos: false,
    });
  
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('message', formData.message);
  
      try {
        await axios.post('https://grec-backend.onrender.com/send-email', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Email inviata con successo');
        // Reset form after successful submission
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
          tos: false,
        });
      } catch (error) {
        console.error('Errore durante l\'invio dell\'email:', error);
        alert('Invio dell\'email fallito');
      }
    };
  
  
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
  
    
    return (
      <>
    
              <form className='Grec--contact-form' onSubmit={handleSubmit}>
                <h2>{currentLanguage === 'en' ? 'Contact us' : 'Contattaci'}</h2>
                <p dangerouslySetInnerHTML={{ __html: currentLanguage === 'en' ? 
                  `Do you have any requests regarding our products?<br /> If you cannot find the information you're looking for on the website, you can contact us by filling out the form or sending us an email.<br /><br /><u>We will respond to all requests.</u>` 
                  : 
                  `Hai qualche richiesta relativa ai nostri prodotti?<br /> Se non riesci a trovare sul sito le informazioni che stai cercando, puoi contattarci compilando il form oppure inviandoci una mail.<br /><br /><u>Risponderemo a tutte le richieste.</u>` 
                }}>
                </p>
                <input
                  className='input-full'
                  name="name"
                  placeholder={currentLanguage === 'en' ? 'Full Name / Company' : 'Nome e Cognome / Società'}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <div className='Grec--contact-head'>
                  <input
                    className='input-full'
                    name="email"
                    type="email"
                    placeholder='Email'
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className='input-full'
                    name="phone"
                    placeholder={currentLanguage === 'en' ? 'Phone' : 'Telefono'}
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
  
                <div className='Grec--contact-body'>
                  <textarea
                    name="message"
                    placeholder={currentLanguage === 'en' ? 'Message' : 'Messaggio'}
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <br /><br />
                  <input
                    name="tos"
                    type="checkbox"
                    checked={formData.tos}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="tos">
                    {currentLanguage === 'en' ? 
                      <>I accept the <Link to="/disclaimer">terms of service</Link></> 
                      : 
                      <>Accetto i <Link to="/disclaimer">termini di servizio</Link></>
                    }
                  </label>
                  <br /><br />
                </div>
                <button type="submit">
                  <span>  {currentLanguage === 'en' ? 'Send' : 'Invia'}</span>
                  <span></span>
                  <IoArrowForward size="22" />
                </button>
              </form>
  
          
      </>
  
    );
  }


  export default Map3D2