import React, { useState, useEffect } from 'react';
import {
  IoArrowBackOutline, IoArrowForwardOutline, IoCloseOutline
} from "react-icons/io5";
import images from '../noSQL/images'
import { useTranslation } from 'react-i18next';
import axios from 'axios';


function Gallery() {

  const [gallery, setGallery] = useState([]);

    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
    };
  
    const handleCloseClick = () => {
      setSelectedImageIndex(null);
    };
  
    const handlePrevClick = () => {
      setSelectedImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    };
  
    const handleNextClick = () => {
      setSelectedImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    };


    const fetchGallery = async (page = 1, allItems = []) => {
      try {
        const response = await axios.get(`https://grec-backend.onrender.com/api/galleria?depth=1&page=${page}`);
        console.log('API Response:', response.data);
    
        const totalPages = response.data.totalPages;
    
        // Collect all items across pages
        const galleryItems = [...allItems, ...response.data.docs];
    
        if (page < totalPages) {
          fetchGallery(page + 1, galleryItems);
        } else {

          const galleryOrdinataPerOrdine = galleryItems.sort((a, b) => a.order - b.order);
    
          const galleryWithImages = galleryOrdinataPerOrdine.map(gallery => ({
            ...gallery,
            imageUrl: gallery.image ? `https://grec-backend.onrender.com/api/media/${gallery.image}` : null
          }));
    
          setGallery(galleryWithImages);
          console.log('Final sorted gallery with images:', galleryWithImages);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }; 
    useEffect(() => {
      fetchGallery(1);
    }, []);
    
    
  
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    return (
      <>
        <section className='page--container'>
          <div className='Grec-title-container'>
            <h1>Gallery</h1>
            <p className="gallery-copy">
              
            {currentLanguage === 'en' ? 'Explore our photo collections that capture the essence of our products and projects.' : 'Esplora le nostre collezioni fotografiche che catturano l\'essenza dei nostri prodotti e progetti.'}
              </p>
          </div>
  
         <div className="gallery-container">
          <div className="image-grid">
            {gallery.map((item, index) => (
              <div key={index} className="image-item" onClick={() => handleImageClick(index)}>
                <img
                  src={item.imageUrl}
                  alt={`Gallery image ${index} ${item.order}`}
                  onError={(e) => {
                    console.error('Image failed to load:', item.imageUrl);
                    e.target.onerror = null;
                    e.target.src = '/placeholder.svg?height=200&width=300';
                  }}
                />
              </div>
            ))}
          </div>
          
  
                      {selectedImageIndex !== null && (
                <div className="modal gallery-modal-control">
                  <IoCloseOutline className='Grec--navbar--icon close-button' onClick={handleCloseClick} />

                  <img className="modal-content" src={gallery[selectedImageIndex].imageUrl} alt="Selected" />
                  {gallery[selectedImageIndex].caption && (
                    <span className="image-caption">{gallery[selectedImageIndex].caption}</span>
                  )}
                  <div className='prev' onClick={handlePrevClick}>
                    <IoArrowBackOutline className='Grec--machine-icon' />
                  </div>
                  <div className='next' onClick={handleNextClick}>
                    <IoArrowForwardOutline className='Grec--machine-icon' />
                  </div>
                </div>
              )}
          </div>
        </section>
      </>
    );
  }

  export default Gallery