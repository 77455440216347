import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';

const QuoteModal = ({ isOpen, handleClose, productTitle }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      subject: '',
      message: '',
      file: null,
      application: '',
      agitationDegree: '',
      tankInfo: {
        baseDimensions: '',
        totalHeight: '',
        maxLiquidLevel: '',
        minLiquidLevel: '',
        mountingPosition: '',
        mountingType: '',
        insertionOpening: '',
        bottomType: '',
        pressure: '',
        temperature: '',
        material: '',
        internalComponents: '',
      },
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleTankInfoChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        tankInfo: {
          ...prevState.tankInfo,
          [name]: value,
        },
      }));
    };

    const handleFileChange = (e) => {
      setFormData(prevState => ({
        ...prevState,
        file: e.target.files[0],
      }));
    };

    const finalSubject = currentLanguage === 'en' 
      ? `Product ${productTitle} quote request`
      : `Richiesta preventivo prodotto ${productTitle}`;

      const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        for (const key in formData) {
          if (key === 'tankInfo') {
            for (const tankKey in formData.tankInfo) {
              formDataToSend.append(`tankInfo[${tankKey}]`, formData.tankInfo[tankKey]);
            }
          } else if (key === 'file' && formData[key]) {
            formDataToSend.append(key, formData[key]);
          } else {
            formDataToSend.append(key, formData[key]);
          }
        }
    
        try {
          await axios.post('https://grec-backend.onrender.com/send-quote-request', formDataToSend, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          alert(currentLanguage === 'en' ? 'Quote request sent successfully' : 'Richiesta di preventivo inviata con successo');
          handleClose(); // Close the modal after successful submission
        } catch (error) {
          console.error('Error sending quote request:', error);
          alert(currentLanguage === 'en' ? 'Failed to send quote request' : 'Invio della richiesta di preventivo fallito');
        }
      };

      const downloadPDF = () => {
        const pdfUrl = process.env.PUBLIC_URL + '/pdf/preventivo.pdf';
        window.open(pdfUrl, '_blank');
      };

    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div 
            className="modal-combined prevenito-form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="modal-content-combined"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
            >
              <button 
                className="close-button-combined"
                onClick={handleClose}
              >
                <IoClose />
              </button>
              <form className='quote-modal-form' onSubmit={handleSubmit}>
                <h2 className='quote-modal-title'>
                  {currentLanguage === 'en' ? "Request a Quote" : "Richiedi un Preventivo"}
                </h2>
                <p className='quote-modal-description'>
                  {currentLanguage === 'en'
                    ? "Fill out the form below to request a personalized quote. Our team will contact you shortly with all the information you need."
                    : "Compila il modulo sottostante per richiedere un preventivo personalizzato. Il nostro team ti contatterà al più presto con tutte le informazioni di cui hai bisogno."}
                </p>
           


    <div className='form-division'  >



    <div>
                <div className='quote-modal-input-group'>
                  <input 
                    className="quote-modal-input" 
                    placeholder={currentLanguage === 'en' ? 'Full Name / Company' : 'Nome e Cognome / Società'} 
                    type="text" 
                    name="name" 
                    value={formData.name} 
                    onChange={handleChange} 
                    required 
                  />
                  <input 
                    className="quote-modal-input" 
                    placeholder='Email' 
                    type="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                  />
                </div>

                <div className='quote-modal-input-group' style={{ display : 'none'  }}>
                  <input 
                    className="quote-modal-input" 
                    placeholder={currentLanguage === 'en' ? 'Subject' : 'Oggetto'} 
                    type="text" 
                    name="subject"  
                    value={finalSubject} 
                    readOnly  
                    required
                    
                  />
                </div>
                
                <div className='quote-modal-input-group'>
                  <textarea 
                    className="quote-modal-textarea" 
                    placeholder={currentLanguage === 'en' ? 'Message' : 'Messaggio'} 
                    name="message" 
                    value={formData.message} 
                    onChange={handleChange} 
                    required
                  ></textarea>
                </div>

              
                <MultilingualSelectors 
  currentLanguage={currentLanguage}
  formData={formData}
  handleChange={handleChange}
/>
</div>







    <div>


        <div className="quote-modal-tank-info">
                  <h3 className="quote-modal-subheader">{currentLanguage === 'en' ? 'Tank Information' : 'Informazioni Serbatoio'}</h3>
                  <div className="quote-modal-input-group flex-input">
                    <input 
                      type="text" 
                      name="baseDimensions" 
                      placeholder={currentLanguage === 'en' ? 'Base Dimensions' : 'Dimensioni base'}
                      value={formData.tankInfo.baseDimensions}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                    <input 
                      type="text" 
                      name="totalHeight" 
                      placeholder={currentLanguage === 'en' ? 'Total Height' : 'Altezza totale'}
                      value={formData.tankInfo.totalHeight}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                  </div>
                  <div className="quote-modal-input-group flex-input">
                    <input 
                      type="text" 
                      name="maxLiquidLevel" 
                      placeholder={currentLanguage === 'en' ? 'Max Liquid Level' : 'Massimo livello liquido'}
                      value={formData.tankInfo.maxLiquidLevel}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                    <input 
                      type="text" 
                      name="minLiquidLevel" 
                      placeholder={currentLanguage === 'en' ? 'Min Liquid Level' : 'Minimo Livello liquido'}
                      value={formData.tankInfo.minLiquidLevel}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                  </div>
                  <div className="quote-modal-input-group flex-input">
                    <input 
                      type="text" 
                      name="mountingPosition" 
                      placeholder={currentLanguage === 'en' ? 'Mounting Position' : 'Posizione fissaggio'}
                      value={formData.tankInfo.mountingPosition}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                    <input 
                      type="text" 
                      name="mountingType" 
                      placeholder={currentLanguage === 'en' ? 'Mounting Type' : 'Tipo fissaggio'}
                      value={formData.tankInfo.mountingType}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                  </div>
                  <div className="quote-modal-input-group flex-input">
                    <input 
                      type="text" 
                      name="insertionOpening" 
                      placeholder={currentLanguage === 'en' ? 'Insertion Opening' : 'Apertura per inserimento'}
                      value={formData.tankInfo.insertionOpening}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                    <input 
                      type="text" 
                      name="bottomType" 
                      placeholder={currentLanguage === 'en' ? 'Bottom Type' : 'Tipo Fondo'}
                      value={formData.tankInfo.bottomType}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                  </div>
                  <div className="quote-modal-input-group flex-input">
                    <input 
                      type="text" 
                      name="pressure" 
                      placeholder={currentLanguage === 'en' ? 'Pressure (bar)' : 'Pressione (bar)'}
                      value={formData.tankInfo.pressure}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                    <input 
                      type="text" 
                      name="temperature" 
                      placeholder={currentLanguage === 'en' ? 'Temperature (°C)' : 'Temperatura (°C)'}
                      value={formData.tankInfo.temperature}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                  </div>
                  <div className="quote-modal-input-group flex-input">
                    <input 
                      type="text" 
                      name="material" 
                      placeholder={currentLanguage === 'en' ? 'Material' : 'Materiale'}
                      value={formData.tankInfo.material}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                    <input 
                      type="text" 
                      name="internalComponents" 
                      placeholder={currentLanguage === 'en' ? 'Internal Components' : 'Componenti interni'}
                      value={formData.tankInfo.internalComponents}
                      onChange={handleTankInfoChange}
                      className="quote-modal-input"
                    />
                  </div>
                </div>

                <div className="quote-modal-file-group">
                <input 
                  type="file" 
                  onChange={handleFileChange} 
                  accept=".pdf,.doc,.docx"
                  className="quote-modal-file-input"
                />
                <button 
                  type="button" 
                  onClick={downloadPDF} 
                  className="quote-modal-download-btn"
                >
                  {currentLanguage === 'en' ? 'Download PDF' : 'Scarica PDF'}
                </button>
              </div>



        </div>



    </div>
                <button type="submit" className="quote-modal-submit-btn">
                  {currentLanguage === 'en' ? 'Send Request' : 'Invia Richiesta'}
                </button>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
};

export default QuoteModal;




const MultilingualSelectors = ({ currentLanguage, formData, handleChange }) => {
  const applicationOptions = [
    { value: "Miscelazione", en: "Mixing", it: "Miscelazione" },
    { value: "Dissoluzione", en: "Dissolution", it: "Dissoluzione" },
    { value: "Sospensione solidi", en: "Solid Suspension", it: "Sospensione solidi" },
    { value: "Dispersione gas", en: "Gas Dispersion", it: "Dispersione gas" },
    { value: "Scambio termico", en: "Heat Exchange", it: "Scambio termico" },
    { value: "Altro", en: "Other", it: "Altro" }
  ];

  const agitationOptions = [
    { value: "Debole", en: "Weak", it: "Debole" },
    { value: "Medio", en: "Medium", it: "Medio" },
    { value: "Elevato", en: "High", it: "Elevato" },
    { value: "Tempo di miscelazione", en: "Mixing Time", it: "Tempo di miscelazione" },
    { value: "Tipo sospensione", en: "Suspension Type (clean bottom or uniform)", it: "Tipo sospensione (fondo pulito o uniforme)" },
  ];

  return (
    <div className="">
      <div>
        <select
          name="application"
          value={formData.application}
          onChange={handleChange}
          required
          className="quote-modal-select"
        >
          <option value="">
            {currentLanguage === 'en' ? 'Application' : 'Applicazione'}
          </option>
          {applicationOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {currentLanguage === 'en' ? option.en : option.it}
            </option>
          ))}
        </select>
      </div>

      <div>
        <select
          name="agitationDegree"
          value={formData.agitationDegree}
          onChange={handleChange}
          required
          className="quote-modal-select"
        >
          <option value="">
            {currentLanguage === 'en' ? 'Agitation Degree' : 'Grado di Agitazione'}
          </option>
          {agitationOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {currentLanguage === 'en' ? option.en : option.it}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};