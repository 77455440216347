const documents = [
    { lang : 'it', title: 'Catalogo generale', link: '/pdf/1_brochure.pdf' },
    { lang : 'it', title: 'Presentazione', link: '/pdf/2 GREC_Presentation.pdf' },
    { lang : 'it', title: 'Manuale uso e manutenzione Agitatori Laterali', link: '/pdf/5-l_Manuale_GREC_2022_ITA _LATERALI.pdf' },
    { lang : 'it', title: 'Manuale uso e manutenzione Agitatori Verticali', link: '/pdf/5-v_Manuale_GREC_2022_ITA_VERTICALI.pdf' },
    { lang : 'it', title: 'Manuale per la selezione degli agitatori', link: '/pdf/6_Manualeperlaselezionedegli_agitatori.pdf' },
    { lang : 'it', title: 'Manuale installazione agitatori laterali', link: '/pdf/7_MANUALE_INSTALLAZIONE_AGITATORI_LATERALI.pdf' },
    { lang : 'it', title: 'Manuale installazione agitatori verticali', link: '/pdf/8_MANUALE_INSTALLAZIONE_AGITATORI_VERTICALI.pdf' },
    { lang : 'it', title: 'Manuale estraibili GUTH', link: '/pdf/09_Catalogo_Guth_IT_GREC.pdf' },
    { lang : 'it', title: 'Manuale miscelatori statici', link: '/pdf/9.Static_mixer_IOM_GREC_2021_ITA.pdf' },
    { lang : 'it', title: 'Foglio dati agitatori', link: '/pdf/11_APPLICATION_DATA_SHEET_AGITATORE.pdf' },
    { lang : 'it', title: 'Foglio dati miscelatori statici', link: '/pdf/11.1_APPLICATION_DATA_SHEET_MISCELATORE_STATICO.pdf' },
    { lang : 'it', title: 'Brochure Agitatori per Fusti', link: '/pdf/13_Scheda_per_fusti.pdf' },
    { lang : 'it', title: 'Brochure Miscelatori Statici', link: '/pdf/10_Catalogo_miscelatori_statici.pdf' },
    { lang : 'it', title: 'Brochure Estraibili GUTH', link: '/pdf/14.15_GREC_Brochure_Estraibili_GUTH.pdf' },
    { lang : 'it', title: 'Finiture Superficiali', link: '/pdf/16_GREC_Finiture_Superficiali.pdf' },
    

    { lang : 'en', title: 'General Brochure', link: '/pdf/1_brochure.pdf' },
    { lang : 'en', title: 'Presentation', link: '/pdf/2_grec_presentation.pdf' },
    { lang : 'en', title: 'Side entry Operation & Manteniance Manual', link: '/pdf/LATERALI_IOM_GREC_2021_ENG.pdf' },
    { lang : 'en', title: 'Top entry Operation & Manteniance Manual', link: '/pdf/VERTICALI_IOM_GREC_2021_ENG.pdf' },
    { lang : 'en', title: 'Manual for Mixer Selection', link: '/pdf/6_GREC_Manual _for_Mixer_Selection.pdf' },
    { lang : 'en', title: 'Manual for Side Entry Mixers installation', link: '/pdf/7_GREC_Manual_for_Side_Entry_Mixers_Installation.pdf' },
    { lang : 'en', title: 'Manual for Top Entry Mixers installation', link: '/pdf/8_GREC_Manual for_Top_Entry_Mixers_Installation.pdf' },
    { lang : 'en', title: 'GUTH Operation & Mainteinance Manual', link: '/pdf/9_GUTH_Operation_&_Maintenance_Manual.pdf' },
    { lang : 'en', title: 'Mixer application data sheet', link: '/pdf/11-GREC-Mixer-application-data-sheet.pdf' },
    { lang : 'en', title: 'Portable Mixer Brochure', link: '/pdf/13_GREC_Portable_Mixer_Brochure.pdf' },
    { lang : 'en', title: 'Static Mixers Brochure', link: '/pdf/14_GREC_Static_Mixers_Brochure.pdf' },
    { lang : 'en', title: 'GUTH Portable Mixer Brochure', link: '/pdf/15_GUTH_Portable_Mixer_Brochure.pdf' },
    { lang : 'en', title: 'Standard Surface Finish', link: '/pdf/16_GREC_Standard Surface_Finish.pdf' }


  ];
  
export default documents;  
