const data = [
  {
    id: 1,
    route: 'AVF',
    category: 'AGITATORI VERTICALI',
    categoryEng : 'VERTICAL AGITATORS',
    img: '/card/avf.jpeg',
    imgHome: '/vito/1.AVF.png',
    link: 'https://sketchfab.com/models/6189f0a9560648029c16d45dff48caaf/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0',
    schedatecnica: '/schede_tecniche/AVF.pdf',
    schedatecnicaEN: '/schede_tecniche/18 GREC Technical Sheet AVF Series.pdf',
    imgDetails: '/images/1.AVF.png',
    title: 'AVF',
    details: {
      header: {
        title: 'SERIE AVF',
        subtitle: 'Agitatori verticali con riduttore a VITE SENZA FINE',
        description: `La gamma di agitatori AVF rappresenta la miglior scelta per applicazioni in serbatoi di piccole dimensioni. Il riduttore a vite senza fine ha notevoli vantaggi: economico, leggero, compatto. Di contro ha efficienza inferiore a quella degli altri riduttori, quindi non risulta conveniente utilizzarlo per le potenze più elevate. L’albero dell’agitatore è direttamente inserito nel cavo del riduttore e fissato con vite di testa, rendendo inutile l’uso di un giunto intermedio.`,
        buttonLabel: 'Scarica la scheda tecnica'
      },
      seriesDetails: [
        {
          title: 'La versione –L',
          description: ' presenta una flangia aggiunta DN con tenuta a labbro inclusa. Serve ad evitare l’uscita di fumi o schizzi, e ad impedire che eventuali perdite di olio dal riduttore finiscano nel prodotto agitato.'
        },
        {
          title: 'La versione –S',
          description: ' presenta una lanterna con flangia aggiunta DN e tenuta meccanica inclusa. Serve contenere la pressione o il vuoto presenti all’interno del serbatoio. In questa versione è presente un giunto sotto flangia per poter spedire con la tenuta montata e l’albero smontato.'
        }
      ]
    },
    detailsEng: {
      header: {
        title: 'AVF SERIES',
        subtitle: 'Vertical agitators with WORM GEAR reducer',
        description: `The AVF agitator range is the best choice for applications in small tanks. The worm gear reducer has significant advantages: economical, lightweight, and compact. However, it has lower efficiency compared to other reducers, so it is not suitable for higher power needs. The agitator shaft is directly inserted into the hollow of the reducer and secured with a head screw, making an intermediate coupling unnecessary.`,
        buttonLabel: 'Download the technical sheet'
      },
      seriesDetails: [
        {
          title: 'The –L version ',
          description: ` features an additional DN flange with an included lip seal. It is designed to prevent the escape of fumes or splashes, and to stop any oil leaks from the reducer from entering the agitated product.`
        },
        {
          title: 'The –S version',
          description: ` features a lantern with an additional DN flange and an included mechanical seal. It is used to contain the pressure or vacuum present inside the tank. In this version, there is a flange coupling to allow shipping with the seal mounted and the shaft disassembled.`
        }
      ]
    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'INDUSTRIA ALIMENTARE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    applicationEng: [
      {
        group: "MINING AND CERAMICS",
        items: ["Addition of glazes and dyes", "Preparation of chemical solutions", "Preparation of screen printing pastes"]
      },
      {
        group: "FOOD INDUSTRY",
        items: ["Preparation and storage of fruit juices and beverages"]
      },
      {
        group: "CHEMICAL INDUSTRY",
        items: ["Chemical reactors", "Detergent industry", "Fertilizer industry", "Fertilizers", "Polyurethanes", "Storage of dirty products or those prone to layering"]
      },
      {
        group: "ENERGY",
        items: ["Biodiesel synthesis phases", "Fermenters for bioethanol"]
      },
      {
        group: "PHARMACEUTICAL INDUSTRY",
        items: ["Large fermenters for the preparation of active ingredients and antibiotics", "Preparation of intermediates and finished products", "Creation of emulsions"]
      },
      {
        group: "WATER TREATMENT",
        items: ["Neutralization, Equalization, Flocculation", "Preparation of Polyelectrolyte", "Preparation of chemical additives", "Flash mixing", "Preparation of lime milk", "Storage of sludge"]
      },
      {
        group: "PAINT INDUSTRY",
        items: ["Preparation and storage of resins and solvents"]
      }
    ],
    
    description1: 'AGITATORI VERTICALI',
    description2: 'LENTI CON RIDUTTORE',
    description3: `AVF con riduttore a vite senza fine. <br/> <br/>
        Maggiormente impiegato in serbatoio chiusi fino a circa 15m3, con diversi sistemi di tenuta.<br/> <br/><u>Potenze fino a 4kW</u>.`,
    description1Eng: 'VERTICAL AGITATORS',
    description2Eng: 'SLOW WITH GEARBOX',
    description3Eng: `AVF with worm gear reducer. <br/> <br/>
            Most commonly used in closed tanks up to about 15m³, with various sealing systems.<br/> <br/><u>Powers up to 4kW</u>.`
        
  },
  {
    id: 2,
    route: 'AVC',
    category: 'AGITATORI VERTICALI',
    categoryEng : 'VERTICAL AGITATORS',
    img: `/card/avc.jpeg`,
    imgHome: '/vito/2.AVC.png',
    link: "https://sketchfab.com/models/a60b65fd32cf48818e12c55bf999f58c/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    schedatecnica: '/schede_tecniche/AVC.pdf',
    schedatecnicaEN: '/schede_tecniche/20 GREC Technical Sheet AVC Series.pdf',
    imgDetails: '/images/2.AVC.png',
    title: "AVC",
    details: {
      header: {
        title: 'SERIE AVC',
        subtitle: 'Agitatori verticali con riduttore a COPPIA CONICA',
        description: `La gamma di agitatori AVC la scelta per chi ha
                      necessità di personalizzazioni e applicazioni
                      impegnative, con serbatoi di media dimensione.
                      Il riduttore a coppia conica ha un efficienza
                      maggiore rispetto al fratello minore AVF, quindi
                      risulta conveniente utilizzarlo per le potenze più
                      elevate.
                      L’albero dell’agitatore è direttamente inserito nel
                      cavo del riduttore e fissato con vite di testa,
                      spesso fornito con albero di comando e
                      campana per alloggiamento tenuta meccanica. `,
        buttonLabel: 'Scarica la scheda tecnica'
      },
      seriesDetails: [
        {
          title: 'La versione –L ',
          description: ` presenta una flangia aggiunta DN con
                        tenuta a labbro inclusa. Serve a tenere tutte le parti
                        bagnate nel materiale richiesto, ad evitare l’uscita di
                        fumi o schizzi, e ad impedire che eventuali perdite di
                        olio dal riduttore finiscano nel prodotto agitato.`
        },
        {
          title: 'La versione –S',
          description: ` presenta una lanterna con flangia
                        aggiunta DN e tenuta meccanica inclusa. Serve
                        contenere la pressione o il vuoto presenti all’interno
                        del serbatoio.
                        In questa versione è presente un giunto sotto flangia
                        per poter spedire con la tenuta montata e l’albero
                        smontato.`
        }
      ]
    },
    detailsEng: {
      header: {
        title: 'AVC SERIES',
        subtitle: 'Vertical agitators with BEVEL GEAR reducer',
        description: `The AVC agitator range is the choice for those who need
                    customizations and demanding applications, with medium-sized tanks.
                    The bevel gear reducer is more efficient compared to the smaller AVF model,
                    making it suitable for higher power needs.
                    The agitator shaft is directly inserted into the hollow of the reducer
                    and secured with a head screw, often supplied with a drive shaft
                    and housing bell for mechanical seal accommodation.`,
        buttonLabel: 'Download the technical sheet'
      },
      seriesDetails: [
        {
          title: 'The –L version ',
          description: ` features an additional DN flange with
                      an included lip seal. It is designed to keep all wet parts
                      in the required material, prevent the escape of fumes or splashes,
                      and stop any oil leaks from the reducer from entering the agitated product.`
        },
        {
          title: 'The –S version',
          description: ` features a lantern with an additional DN flange
                      and an included mechanical seal. It is used to contain the pressure
                      or vacuum present inside the tank.
                      In this version, a flange coupling is included to allow
                      shipping with the seal mounted and the shaft disassembled.`
        }
      ]
    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'INDUSTRIA ALIMENTARE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    applicationEng: [
      {
        group: "MINING AND CERAMICS",
        items: ["Addition of glazes and dyes", "Preparation of chemical solutions", "Preparation of screen printing pastes"]
      },
      {
        group: "FOOD INDUSTRY",
        items: ["Preparation and storage of fruit juices and beverages"]
      },
      {
        group: "WATER TREATMENT",
        items: ["Neutralization, Equalization, Flocculation", "Preparation of Polyelectrolyte", "Preparation of chemical additives", "Flash mixing", "Preparation of lime milk", "Storage of sludge"]
      },
      {
        group: "PAINT INDUSTRY",
        items: ["Preparation and storage of resins and solvents"]
      }
    ],
    
    description1: "AGITATORI VERTICALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `AVC riduttore a coppia conica.<br/> <br/>Trova impiego su serbatoi di grosse dimensioni quando è richiesto motore orizzontale.`,
    description1Eng: "VERTICAL AGITATORS",
    description2Eng: "SLOW WITH GEARBOX",
    description3Eng: `AVC bevel gear reducer.<br/> <br/>Used on large tanks when a horizontal motor is required.` 

  },
  {
    id: 3,
    route: 'AVS',
    category: 'AGITATORI VERTICALI',
    categoryEng : 'VERTICAL AGITATORS',
    img: `/card/avs.jpeg`,
    imgHome: '/vito/3.AVS.png',
    link: "https://sketchfab.com/models/ff7fdd58674841a495726a371d9e2481/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    schedatecnica: '/schede_tecniche/AVS0-4.pdf',
    schedatecnica2: '/schede_tecniche/AVS5-11.pdf',
    schedatecnicaEN:'/schede_tecniche/21 GREC Technical Sheet AVS Helical Series.pdf',
    schedatecnica2EN:'/schede_tecniche/22 GREC  Technical Sheet AVS Planetary Series.pdf',
    imgDetails: '/images/3.AVS.png',
    title: "AVS",
    details: {
      header: {
        title: 'SERIE AVS',
        subtitle: ' Agitatori verticali con riduttore COASSIALE',
        description: `Il riduttore coassiale, selezionato con elevati
          fattori di servizio, presenta carcassa in ghisa
          rigida e cuscinetti rinforzati per assorbire sia
          la coppia trasmessa dal motore che il carico
          radiali restituito dalle giranti in rotazione,
          senza bisogno di lanterne aggiuntive.
          Il gruppo si estende con il motore in
          verticale ed è quindi preferito quando il tetto
          del serbatoio non presenta spazi liberi.
          L’albero dell’agitatore è fissato all’albero
          sporgente del riduttore con giunto rigido a
          manicotto. `,
        buttonLabel: 'Scarica la scheda tecnica AVS 0÷4'
      },
      header2: {
        title: 'SERIE AVS',
        subtitle: 'Agitatori verticali con riduttore EPICICLOIDALE ',
        description: `Il riduttore epicicloidale, selezionato con
          elevati fattori di servizio, presenta carcassa
          in ghisa rigida e cuscinetti rinforzati per
          assorbire sia la coppia trasmessa dal
          motore che il carico radiali restituito dalle
          giranti in rotazione, senza bisogno di
          lanterne aggiuntive.
          Il gruppo si estende con il motore in
          verticale ed è quindi preferito quando il tetto
          del serbatoio non presenta spazi liberi.
          L’albero dell’agitatore è fissato all’albero
          sporgente del riduttore con giunto rigido a
          flangia.`,
        buttonLabel: 'Scarica la scheda tecnica AVS 5÷11'
      },
    },
      detailsEng: {
        header: {
          title: 'AVS SERIES',
          subtitle: ' Vertical agitators with COAXIAL reducer',
          description: `The coaxial reducer, selected with high service factors, features a rigid cast iron casing and reinforced bearings to absorb both the torque transmitted from the motor and the radial load returned by the rotating impellers, without the need for additional lanterns. The unit extends with the motor in a vertical position, making it the preferred choice when there is no free space above the tank. The agitator shaft is attached to the protruding reducer shaft with a rigid sleeve coupling. `,
          buttonLabel: 'Download the AVS 0÷4 technical sheet'
        },
        header2: {
          title: 'AVS SERIES',
          subtitle: 'Vertical agitators with PLANETARY reducer ',
          description: `The planetary reducer, selected with high service factors, features a rigid cast iron casing and reinforced bearings to absorb both the torque transmitted from the motor and the radial load returned by the rotating impellers, without the need for additional lanterns. The unit extends with the motor in a vertical position, making it the preferred choice when there is no free space above the tank. The agitator shaft is attached to the protruding reducer shaft with a rigid flange coupling.`,
          buttonLabel: 'Download the AVS 5÷11 technical sheet'
        },

    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Vasche di stoccaggio acque di processo o lavaggio','Omogeneizzazione barbottina','Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'INDUSTRIA ALIMENTARE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Digestori Biogas','Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    applicationEng: [
      {
        group: "MINING AND CERAMICS",
        items: [
          "Storage tanks for process or washing water",
          "Homogenization of slurry",
          "Addition of glazes and dyes",
          "Preparation of chemical solutions",
          "Preparation of screen printing pastes"
        ]
      },
      {
        group: "FOOD INDUSTRY",
        items: ["Preparation and storage of fruit juices and beverages"]
      },
      {
        group: "CHEMICAL INDUSTRY",
        items: [
          "Chemical reactors",
          "Detergent industry",
          "Fertilizer industry",
          "Fertilizers",
          "Polyurethanes",
          "Storage of dirty products or those prone to stratification"
        ]
      },
      {
        group: "ENERGY",
        items: [
          "Biogas digesters",
          "Biodiesel synthesis stages",
          "Fermenters for bioethanol"
        ]
      },
      {
        group: "PHARMACEUTICAL INDUSTRY",
        items: [
          "Large fermenters for the preparation of active ingredients and antibiotics",
          "Preparation of intermediates and finished products",
          "Creation of emulsions"
        ]
      },
      {
        group: "WATER TREATMENT",
        items: [
          "Neutralization, Equalization, Flocculation",
          "Preparation of Polyelectrolyte",
          "Preparation of chemical additives",
          "Flash mixing",
          "Preparation of lime milk",
          "Storage of sludge"
        ]
      },
      {
        group: "PAINT INDUSTRY",
        items: ["Preparation and storage of resins and solvents"]
      }
    ],
    
    description1: "AGITATORI VERTICALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `
      AVS riduttore coassiale a ingranaggi o epicicloidale.<br/><br/>
  Trova impiego maggiormente in vasche aperte. <br/><br/>
  Costruzione con motore in verticale e albero sporgente sotto il riduttore.<br/><br/>
   Le taglie maggiori sono disponibili anche con uscita rinforzata con cuscinetti molto distanziati per poter gestire alberi molto lunghi.
      `,
    description1Eng: "VERTICAL AGITATORS",
    description2Eng: "SLOW WITH GEARBOX",
    description3Eng: `
      AVS coaxial gear or epicyclic reducer.<br/><br/>
      Mainly used in open tanks.<br/><br/>
      Construction with the motor in a vertical position and the shaft protruding below the reducer.<br/><br/>
      Larger sizes are also available with reinforced output and widely spaced bearings to handle very long shafts.
      `

},
  {
    id: 4,
    route: 'AVL',
    category: 'AGITATORI VERTICALI',
    categoryEng : 'VERTICAL AGITATORS',
    img: `/card/avl.jpeg`,
    imgHome: '/vito/4.AVL.png',
    link: "https://sketchfab.com/models/8d25988dc3fb4747a88132c765d2f113/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    schedatecnica: '/schede_tecniche/AVL.pdf',
    schedatecnicaEN: '/schede_tecniche/19 GREC Technical Sheet AVL Series.pdf',
    imgDetails: '/images/4.AVL.png',
    title: "AVL",
    details: {
      header: {
        title: 'SERIE AVL',
        subtitle: 'Agitatori verticali con riduttore ad ASSI PARALLELI',
        description: `Il riduttore ad assi paralleli, selezionato con
          elevati fattori di servizio, presenta carcassa in
          ghisa rigida e cuscinetti rinforzati per assorbire
          sia la coppia trasmessa dal motore che il carico
          radiali restituito dalle giranti in rotazione, senza
          bisogno di lanterne aggiuntive.
          Il gruppo si estende con il motore in verticale ed
          è quindi preferito quando il tetto del serbatoio
          non presenta spazi liberi.
          L’albero dell’agitatore è direttamente inserito nel
          cavo del riduttore e fissato con vite di testa,
          rendendo inutile l’uso di un giunto intermedio`,
        buttonLabel: 'Scarica la scheda tecnica'
      },
      seriesDetails: [
        {
          title: 'La versione –L',
          description: ` presenta una flangia aggiunta DN con
            tenuta a labbro inclusa. Serve a tenere tutte le parti
            bagnate nel materiale richiesto, ad evitare l’uscita di
            fumi o schizzi, e ad impedire che eventuali perdite di
            olio dal riduttore finiscano nel prodotto agitato `
        },
        {
          title: 'La versione –S',
          description: ` presenta una lanterna con flangia
            aggiunta DN e tenuta meccanica inclusa. Serve
            contenere la pressione o il vuoto presenti all’interno
            del serbatoio.
            In questa versione è presente un giunto sotto flangia
            per poter spedire con la tenuta montata e l’albero
            smontato.`,
        }
      ]
    },
    detailsEng: {
      header: {
      title: "AVL SERIES",
      subtitle: "Vertical agitators with PARALLEL SHAFTS reducer",
      description: "The parallel shaft reducer, selected with high service factors, features a rigid cast iron casing and reinforced bearings to absorb both the torque transmitted from the motor and the radial load returned by the rotating impellers, without the need for additional lanterns. The unit extends with the motor in a vertical position, making it the preferred choice when there is no free space above the tank. The agitator shaft is directly inserted into the hollow reducer shaft and secured with a head screw, making the use of an intermediate coupling unnecessary.",
      buttonLabel: "Download the technical sheet"
    },
    seriesDetails: [
      {
        title: "The –L version",
        description: "features an added DN flange with an included lip seal. It serves to keep all wetted parts in the required material, to prevent the release of fumes or splashes, and to ensure that any oil leaks from the reducer do not end up in the agitated product."
      },
      {
        title: "The –S version",
        description: "features a lantern with an added DN flange and an included mechanical seal. It is used to contain the pressure or vacuum inside the tank. In this version, a coupling under the flange is provided, allowing the seal to be shipped mounted and the shaft disassembled."
    }
  ]
    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Vasche di stoccaggio acque di processo o lavaggio','Omogeneizzazione barbottina','Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'INDUSTRIA ALIMENTARE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    applicationEng: [
      {
        group: 'MINING AND CERAMICS',
        items: ['Storage tanks for process or washing water', 'Homogenization of slurry', 'Addition of glazes and dyes', 'Preparation of chemical solutions', 'Preparation of screen printing pastes']
      },
      {
        group: 'FOOD INDUSTRY',
        items: ['Preparation and storage of fruit juices and beverages']
      },
      {
        group: 'CHEMICAL INDUSTRY',
        items: ['Chemical reactors', 'Detergent industry', 'Fertilizer industry', 'Fertilizers', 'Polyurethanes', 'Storage of dirty products or those prone to stratification']
      },
      {
        group: 'ENERGY',
        items: ['Biodiesel synthesis stages', 'Fermenters for bioethanol']
      },
      {
        group: 'PHARMACEUTICAL INDUSTRY',
        items: ['Large fermenters for the preparation of active ingredients and antibiotics', 'Preparation of intermediates and finished products', 'Creation of emulsions']
      },
      {
        group: 'WATER TREATMENT',
        items: ['Neutralization, Equalization, Flocculation', 'Preparation of Polyelectrolyte', 'Preparation of chemical additives', 'Flash mixing', 'Preparation of lime milk', 'Storage of sludge']
      },
      {
        group: 'PAINT INDUSTRY',
        items: ['Preparation and storage of resins and solvents']
      }
    ],
    
    description1: "AGITATORI VERTICALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `
      
      AVL con riduttore ad assi paralleli.<br/><br/>
  Soluzione di alta qualità per ogni dimensione di serbatoio. <br/><br/>
  L’albero dell’agitatore si inserisce direttamente nell’albero cavo del riduttore. <br/><br/>
  Motore in posizione verticale.
      `,
    description1Eng: "VERTICAL AGITATORS",
    description2Eng: "SLOW WITH GEARBOX",
    description3Eng: `
      AVL with parallel axis reducer.<br/><br/>
      High-quality solution for any tank size.<br/><br/>
      The agitator shaft fits directly into the hollow shaft of the reducer.<br/><br/>
      Motor in a vertical position.
      `
  },

  {
    id: 5,
    route: 'AV',
    category: 'AGITATORI VERTICALI',
    categoryEng : 'VERTICAL AGITATORS',
    img: `/card/av.jpeg`,
    imgHome: '/vito/5.AVh.png',
    link: "https://sketchfab.com/models/e3b20b3dc1c649a9a997978e5c65600a/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    schedatecnica: '/schede_tecniche/AV.pdf',
    schedatecnicaEN: '/schede_tecniche/17 GREC Technical Sheet AV0 Series.pdf',
    imgDetails: '/images/5.AVh.png',
    title: "AV",
    details: {
      header: {
        title: 'SERIE AV',
        subtitle: 'Agitatori verticali veloci',
        description: `Gli agitatori verticali veloci, serie AV, sono la miglior soluzione per miscelazioni in serbatoi piccoli.
          Sono anche utilizzati per dissoluzioni o dispersioni di solidi in una massa liquida.
          Per le miscelazioni e le dissoluzioni si usano giranti marine (M), mentre per le dispersioni si preferisce l’uso di
          giranti a dente di sega o cowles (S).
          A parità di volume liquido, le potenze in gioco cambiano moltissimo a seconda se si tratti di miscelazione o
          dispersione. `,
        buttonLabel: 'Scarica la scheda tecnica'
      },
      seriesDetails: [
        {
          title: '',
          description: ` Il gruppo guida è costituito dal solo motore, che ha la
            flangia di connessione con risalto di centraggio,
            oppure si possono aggiungere altri componenti:`
        },
        {
          title: '-Flangia',
          description: ` piana in alluminio (serie AV0f) per avere la
            flangia di connessione piana invece che con risalto `,
        },
        {
          title: '-Lanterna',
          description: `da fusione in alluminio con cuscinetto
            reggispinta per poter fare alberi più lunghi (serie AV1 /
            AV2 / AV3 / AV4)`,
        },
        // {
        //   title: '',
        //   description: `Nel caso sia necessaria flangia INOX con sistema di
        //   tenuta, essa può essere a labbro (versione –L) o
        //   meccanica a secco (versione –S). `,
        // }
      ]
    },
    detailsEng: {
      header: {
        title: "AV SERIES",
        subtitle: "High-speed vertical agitators",
        description: "The high-speed vertical agitators of the AV series are the best solution for mixing in small tanks. They are also used for dissolving or dispersing solids in a liquid mass. Marine impellers (M) are used for mixing and dissolving, while sawtooth or cowles impellers (S) are preferred for dispersion. For the same liquid volume, the power requirements vary significantly depending on whether it’s mixing or dispersion.",
        buttonLabel: "Download the technical sheet"
      },
      seriesDetails: [
        {
          title: "",
          description: "The drive unit consists solely of the motor, which has a connection flange with a centering boss, or additional components can be added:"
        },
        {
          title: "-Flange",
          description: "flat aluminum flange (AV0f series) to provide a flat connection flange instead of one with a boss"
        },
        {
          title: "-Lantern",
          description: "cast aluminum lantern with thrust bearing for longer shafts (AV1 / AV2 / AV3 / AV4 series)"
        }
      ]
    },
    application: [
      {
        group: 'MINIERE E CERAMICA',
        items: ['Aggiunta smalti e coloranti', 'Preparazione soluzioni chimiche', 'Preparazione paste serigrafiche']
      },
      {
        group: 'INDUSTRIA ALIMENTARE',
        items: ['Preparazione e stoccaggio succhi di frutta e bevande','Preparazione soluzioni zuccherine e salamoia']
      },
      {
        group: 'INDUSTRIA CHIMICA',
        items: ['Reattori chimici', 'Industria detergenza', 'Industria fertilizzanti', 'Fertilizzanti', 'Poliuretani', 'Stoccaggio prodotti sporchi o che tendono a stratificare']
      },
      {
        group: 'ENERGIA',
        items: ['Fasi di sintesi del biodiesel', 'Fermentatori per bioetanolo']
      },
      {
        group: 'INDUSTRIA FARMACEUTICA',
        items: ['Grossi fermentatori per preparazione principi attivi e antibiotici', 'Preparazione intermedi e prodotto finito', 'Creazione di emulsioni']
      },
      {
        group: 'TRATTAMENTO ACQUE',
        items: ['Neutralizzazione, Equalizzazione, Flocculazione', 'Preparazione Polielettrolita', 'Preparazione additivi chimici', 'Flash mixing', 'Preparazione latte di calce', 'Stoccaggio fanghi']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparazione e stoccaggio resine e solventi']
      }
    ],
    applicationEng: [
      {
        group: 'MINING AND CERAMICS',
        items: ['Addition of glazes and dyes', 'Preparation of chemical solutions', 'Preparation of screen printing pastes']
      },
      {
        group: 'FOOD INDUSTRY',
        items: ['Preparation and storage of fruit juices and beverages', 'Preparation of sugary solutions and brine']
      },
      {
        group: 'CHEMICAL INDUSTRY',
        items: ['Chemical reactors', 'Detergent industry', 'Fertilizer industry', 'Fertilizers', 'Polyurethanes', 'Storage of dirty products or those prone to stratification']
      },
      {
        group: 'ENERGY',
        items: ['Biodiesel synthesis stages', 'Fermenters for bioethanol']
      },
      {
        group: 'PHARMACEUTICAL INDUSTRY',
        items: ['Large fermenters for the preparation of active ingredients and antibiotics', 'Preparation of intermediates and finished products', 'Creation of emulsions']
      },
      {
        group: 'WATER TREATMENT',
        items: ['Neutralization, Equalization, Flocculation', 'Preparation of Polyelectrolyte', 'Preparation of chemical additives', 'Flash mixing', 'Preparation of lime milk', 'Storage of sludge']
      },
      {
        group: 'PAINT INDUSTRY',
        items: ['Preparation and storage of resins and solvents']
      }
    ],
    
    description1: "AGITATORI VERTICALI",
    description2: "VELOCI SENZA RIDUTTORI",
    description3: `AVS riduttore coassiale a ingranaggi o epicicloidale. Trova impiego maggiormente in vasche aperte.   <br/> <br/>
      Costruzione con motore in verticale e albero sporgente sotto il riduttore.  <br/> <br/>
      Le taglie maggiori sono disponibili anche con uscita rinforzata con cuscinetti molto distanziati per poter gestire alberi molto lunghi.`,
    description1Eng: "VERTICAL AGITATORS",
    description2Eng: "FAST WITHOUT GEARBOXES",
    description3Eng: `AVS with coaxial gear or epicyclic reducer. Primarily used in open tanks.<br/><br/>
          Construction with the motor in a vertical position and the shaft protruding below the reducer.<br/><br/>
          Larger sizes are also available with reinforced output and widely spaced bearings to accommodate very long shafts.`
      
  },


  // {
  //   id: 6,
  //   route: 'TD',
  //   category: 'AGITATORI VERTICALI',
  //   categoryEng : 'VERTICAL AGITATORS',
  //   img: ``,
  //   link: "",
  //   title: "TD",
  //     imgDetails: '',
  //   details: {
  //     header: {
  //       title: 't',
  //       subtitle: '',
  //       description: ``,
  //       buttonLabel: ''
  //     },
  //     seriesDetails: [
  //       {
  //         title: '',
  //         description: ''
  //       },
  //       {
  //         title: '',
  //         description: ''
  //       }
  //     ]
  //   },
  //   detailsEng: {
  //     header: {
  //       title: '',
  //       subtitle: '',
  //       description: ``,
  //       buttonLabel: ''
  //     },
  //     seriesDetails: [
  //       {
  //         title: '',
  //         description: ''
  //       },
  //       {
  //         title: '',
  //         description: ''
  //       }
  //     ]
  //   },
  //   application: [
  //     {
  //       group: 'INDUSTRIA ALIMENTARE',
  //       items: ['Preparazione miscele ed emulsioni']
  //     },
  //     {
  //       group: 'INDUSTRIA FARMACEUTICA',
  //       items: ['Creazione di emulsioni']
  //     },

  //   ],
  //   applicationEng: [
  //     {
  //       group: 'FOOD INDUSTRY',
  //       items: ['Preparation of mixtures and emulsions']
  //     },
  //     {
  //       group: 'PHARMACEUTICAL INDUSTRY',
  //       items: ['Creation of emulsions']
  //     },

  //   ],
  //   description1: "AGITATORI VERTICALI",
  //   description2: "TURBODISPERSORI",
  //   description3: `TD con sistema statore/rotore per la massima efficacia di dispersione a 1400 RPM.`,
  //   description1Eng: "VERTICAL AGITATORS",
  //   description2Eng: "TURBODISPERSERS",
  //   description3Eng: "TD with stator/rotor system for maximum dispersion efficiency at 1400 RPM."

  // },


  {
    id: 7,
    route: 'ALF',
    category: 'AGITATORI LATERALI',
    categoryEng : 'SIDE AGITATORS',
    img: `/card/alf.jpeg`,
    imgHome: '/vito/ALF.png',
    link: "https://sketchfab.com/models/17c1780d4a634e77b9a4cbbe70361854/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "ALF",
    schedatecnica: '/schede_tecniche/ALF.pdf',
    schedatecnicaEN: '/schede_tecniche/25 GREC Technical Sheet ALF Series.pdf',
    imgDetails: '/images/ALF.png',
    details: {
      header: {
        title: 'SERIE ALF',
        subtitle: '',
        description: `


`,
        buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: 'ALF',
          description: '| con tenuta dentro il serbatoio',
          buttonLink : '/schede_tecniche/Standard_ALF.pdf',
          button : ' Scheda standard ALF',
        },
        {
          title: 'ALF‐E',
          description: '| con tenuta sanitaria dentro il serbatoio',
          buttonLink : '/schede_tecniche/Standard_ALF-E.pdf',
          button : ' Scheda standard ALF-E',
        },
        {
          title: 'ALFX',
          description: '| con tenuta dentro il serbatoio Certificati ATEX per zona 1',
          buttonLink : '/schede_tecniche/Standard_ALFx.pdf',
          button : 'Scheda standard ALFX',
        },
        {
          title: 'ALF‐S',
          description: '| con tenuta a cartuccia in lanterna',
          buttonLink : '/schede_tecniche/Standard_ALF-S.pdf',
          button : 'Scheda standard ALF-S',
        }
      ]
    },
    detailsEng: {
      header: {
        title: "ALF SERIES",
        subtitle: "",
        description: "",
        buttonLabel: "Technical Sheet"
      },
      seriesDetails: [
        {
          title: "ALF",
          description: "| with seal inside the tank",
          buttonLink: "/schede_tecniche/32 GREC Standard Mixers ALF Series.pdf",
          button: "Standard ALF Sheet"
        },
        {
          title: "ALF-E",
          description: "| with sanitary seal inside the tank",
          buttonLink: "/schede_tecniche/33 GREC Standard Mixers ALF-E Series.pdf",
          button: "Standard ALF-E Sheet"
        },
        {
          title: "ALFX",
          description: "| with seal inside the tank, ATEX Certified for Zone 1",
          buttonLink: "/schede_tecniche/34 GREC Standard Mixers ALFx Series.pdf",
          button: "Standard ALFX Sheet"
        },
        {
          title: "ALF-S",
          description: "| with cartridge seal in lantern",
          buttonLink: "/schede_tecniche/35 GREC Standard Mixers ALF-S Series.pdf",
          button: "Standard ALF-S Sheet"
        }
      ]
    },
    application: [
      {
        group: 'INDUSTRIA ALIMENTARE',
        items: ['Stoccaggio prodotti enologici']
      },

    ],
    applicationEng: [
      {
        group: 'FOOD INDUSTRY',
        items: ['Storage of oenological products']
      }
    ],
    description1: "AGITATORI LATERALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `ALF, taglie inferiori con riduttore a vite senza fine.`,
    description1Eng: "SIDE AGITATORS",
    description2Eng: "SLOW WITH GEARBOX",
    description3Eng: `ALF, smaller sizes with worm gear reducer.`

  },
  {
    id: 8,
    route: 'ALC',
    category: 'AGITATORI LATERALI',
    categoryEng : 'SIDE AGITATORS',
    img: `/card/alc.jpeg`,
    imgHome: '/vito/ALC.png',
    link: "https://sketchfab.com/models/8e50476b0ed54b2bb3000bace5df98cc/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    schedatecnica: '/schede_tecniche/ALC.pdf',
    schedatecnicaEN: '/schede_tecniche/26 GREC Technical Sheet ALC Series.pdf',
    title: "ALC",
    imgDetails: '/images/ALC.png',
    details: {
      header: {
        title: 'SERIE ALC',
        subtitle: '',
        description: `


`,
        buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: 'Agitatori laterali con riduttore ortogonale serie ALC',
          description: '| con tenuta a cartuccia in lanterna',
          buttonLink : '/schede_tecniche/ALC-2.pdf',
          button : 'Scheda tecnica ALC',
        },
      
      ]
    },
    detailsEng: {
      header: {
        title: "ALC SERIES",
        subtitle: "",
        description: "",
        buttonLabel: "Technical Sheet"
      },
      seriesDetails: [
        {
          title: "Side agitators with orthogonal gearbox, ALC series",
          description: "| with cartridge seal in lantern",
          buttonLink: "/schede_tecniche/36 GREC Standard Mixers ALC Series.pdf",
          button: "ALC Technical Sheet"
        }
      ]
    },
    application: [
      {
        group: 'INDUSTRIA ALIMENTARE',
        items: ['Stoccaggio olio, acidi grassi']
      },
      {
        group: 'ENERGIA',
        items: ['Digestori Biogas', 'Desolforazione fumi (FGD)']
      },
      {
        group: 'OLI & GAS',
        items: ['Additivazione gasoli', 'Miscelazione e stoccaggio di diversi gasoli']
      }

    ],
    applicationEng: [
      {
        group: 'FOOD INDUSTRY',
        items: ['Storage of oil, fatty acids']
      },
      {
        group: 'ENERGY',
        items: ['Biogas digesters', 'Flue gas desulfurization (FGD)']
      },
      {
        group: 'OIL & GAS',
        items: ['Additivation of diesel fuels', 'Blending and storage of different diesel fuels']
      }
    ],
    
    description1: "AGITATORI LATERALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `ALC taglie maggiori con riduttore ortogonale.`,
    description1Eng: "SIDE AGITATORS",
    description2Eng: "SLOW WITH GEARBOX",
    description3Eng: `ALC larger sizes with orthogonal reducer.`
    
  },
  {
    id: 9,
    route: 'ALS',
    category: 'AGITATORI LATERALI',
    categoryEng : 'SIDE AGITATORS',
    img: `/card/als.jpeg`,
    imgHome: '/vito/ALS.png',
    link: "https://sketchfab.com/models/d21b6443634c4b84800a825619cc1bb7/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "ALS",
    imgDetails: '/images/ALS.png',
    details: {
      header: {
        title: 'SERIE ALS',
        subtitle: '',
        description: `


`,
        // buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: 'ALS',
          description: '| con tenuta W dentro il serbatoio',
          buttonLink : '/schede_tecniche/ALS.pdf',
          button : 'Scheda tecnica ALS',
        },
        {
          title: 'ALS-E',
          description: '| con tenuta igienizzabile dentro il serbatoio',
          buttonLink : '/schede_tecniche/ALS-E.pdf',
          button : 'Scheda tecnica ALS-E',
        },
      
      ]
    },
    detailsEng: {
      header: {
        title: "ALS SERIES",
        subtitle: "",
        description: "",
        // buttonLabel: "Technical Sheet"
      },
      seriesDetails: [
        {
          title: "ALS",
          description: "| with W seal inside the tank",
          buttonLink: "/schede_tecniche/37 GREC Standard Mixers ALS Series 2017.pdf",
          button: "ALS Technical Sheet"
        },
        {
          title: "ALS-E",
          description: "| with hygienic seal inside the tank",
          buttonLink: "/schede_tecniche/38 GREC Standard MixersALS-E Series.pdf",
          button: "ALS-E Technical Sheet"
        }
      ]
    },
    application: [
      {
        group: 'INDUSTRIA ALIMENTARE',
        items: ['Stoccaggio prodotti enologici']
      }

    ],
    applicationEng: [
      {
        group: 'FOOD INDUSTRY',
        items: ['Storage of oenological products']
      }
    ],
    description1: "AGITATORI LATERALI",
    description2: "LENTI CON RIDUTTORE",
    description3: `ALS con riduttore ad assi paralleli.`,
    description1Eng: "SIDE AGITATORS",
    description2Eng: "SLOW WITH GEARBOX",
    description3Eng: `ALS with parallel shaft reducer.`

  },
  {
    id: 10,
    route: 'ALH',
    category: 'AGITATORI LATERALI',
    categoryEng : 'SIDE AGITATORS',
    img: `/card/alh.jpeg`,
    imgHome: '/vito/ALH.png',
    schedatecnica: '/schede_tecniche/AL.pdf',
    schedatecnicaEN: '/schede_tecniche/24 GREC Technical Sheet AL Series.pdf',
    link: "https://sketchfab.com/models/9fc22a0afbf2478eb5c8049ccb1e52c4/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "ALH",
    imgDetails: '/images/ALH.png',
    details: {
      header: {
        title: 'SERIE ALH',
        subtitle: '',
        description: `


`,
        buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: 'AL0',
          description: '| senza lanterna con tenuta dentro il serbatoio',
          buttonLink : '/schede_tecniche/Standard_AL0.pdf',
          button : ' Scheda standard AL0',
        },
        {
          title: 'AL',
          description: '| con lanterna con tenuta dentro il serbatoio',
          buttonLink : '/schede_tecniche/Standard_AL.pdf',
          button : 'Scheda standard AL',
        },
        {
          title: 'ALd',
          description: '| con lanterna con tenuta dentro il serbatoio Motore a doppia polarità',
          buttonLink : '/schede_tecniche/Standard_ALd.pdf',
          button : 'Scheda standard ALd',
        },
      
      ]
    },
    detailsEng: {
      header: {
        title: "ALH SERIES",
        subtitle: "",
        description: "",
        buttonLabel: "Technical Sheet"
      },
      seriesDetails: [
        {
          title: "AL0",
          description: "| without lantern with seal inside the tank",
          buttonLink: "/schede_tecniche/29 GREC Standard Mixers AL0 Series.pdf",
          button: "Standard AL0 Sheet"
        },
        {
          title: "AL",
          description: "| with lantern with seal inside the tank",
          buttonLink: "/schede_tecniche/24 GREC Technical Sheet AL Series.pdf",
          button: "Standard AL Sheet"
        },
        {
          title: "ALd",
          description: "| with lantern with seal inside the tank Double polarity motor",
          buttonLink: "/schede_tecniche/31 GREC Standard Mixers ALd Series.pdf",
          button: "Standard ALd Sheet"
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI LATERALI",
    description2: "DIRETTI",
    description3: `AL con o senza cuscinetto reggispinta aggiuntivo.
Solo tenuta meccanica interna al serbatoio.`,
    description1Eng: "SIDE AGITATORS",
    description2Eng: "DIRECT",
    description3Eng: `AL with or without additional thrust bearing. Only mechanical seal inside the tank.`

  },

  {
    id: 11,
    route: 'GUTH-RA',
    category: 'AGITATORI PORTATILI',
    categoryEng : 'PORTABLE AGITATORS',
    img: `/card/guthrah115.png`,
    schedatecnica: '/schede_tecniche/BROCHURE-GUTH.pdf',
    link: "https://sketchfab.com/models/7f6f62c1de8c468583d23c1d3ef1b77e/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "GUTH RA",
    imgDetails: '/images/GUTHRAH.png',       
    details: {
      header: {
        title: 'GUTH-RA',
        subtitle: '',
        description: ``,
        buttonLabel: 'Scheda tecnica'
      },
      seriesDetails: [
        {
          title: '',
          description: `Gli agitatori mobili RA Guth vengono utilizzati per
miscelare, agitare, distribuire e omogeneizzare liquidi
acquosi e fluidi a media viscosità. Sono utilizzati
principalmente nell'industria del vino e nelle distillerie,
ma anche in molte altre aree dell'industria delle
bevande in cui è richiesta una miscelazione delicata.`
        },
        {
          title: `L'estrema durata e l'agitazione favorevole al prodotto
con un risultato di miscelazione estremamente
buono`,
          description:  `sono le caratteristiche classiche della nostra
serie RA. Tutto ciò rappresenta la soluzione dei
problemi di miscelazione a lungo termine. `
        }
      ]
    },
    detailsEng: {
      header: {
        title: 'GUTH-RA',
        subtitle: '',
        description: ``,
        buttonLabel: 'Technical Sheet'
      },
      seriesDetails: [
        {
          title: '',
          description: `RA Guth mobile agitators are used for mixing, stirring, distributing, and homogenizing aqueous liquids and medium-viscosity fluids. They are primarily used in the wine industry and distilleries, but also in many other areas of the beverage industry where gentle mixing is required.`
        },
        {
          title: `Extreme durability and product-friendly agitation with an extremely good mixing result`,
          description: `are the classic features of our RA series. All this represents the long-term solution to mixing problems.`
        }
      ]
      
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI PORTATILI",
    description2: "LATERALI PER CANTINA",
    description3: `GUTH RA - Albero estraibile ed elica pieghevole per rimozione a serbatoio pieno. Utilizzato per serbatoio fino a 1000 Hl. Vedi la brochure dedicata per gli accessori disponibili.`,
    description1Eng: "PORTABLE AGITATORS",
    description2Eng: "LATERAL FOR WINERIES",
    description3Eng: "GUTH RA - Retractable shaft and pivoting propeller for being removable with full vessel. Used for tank up to 1000 Hl. Detail and accessory on dedicated brochure."    
  },
  {
    id: 12,
    route: 'AVF-IBC',
    category: '',
    category: 'AGITATORI PORTATILI',
    categoryEng : 'PORTABLE AGITATORS',
    img: `/card/portatile.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/70cfd07b863549e6ae903197d6e4c5fe/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "AVF-IBC",
    imgDetails: '/images/AV-PORT.png',
    schedatecnica: '/schede_tecniche/AVSIBC.pdf',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: '',
          description: `GREC ha standardizzato una gamma di agitatori per
contenitori da 200 e 1000 litri. Gli agitatori sono
portatili, facilmente maneggiabili dall’utente o con
l’aiuto di mezzi di sollevamento tipo muletto, paranco
ecc. Si possono fissare al contenitore o a strutture
esterne.`
        },
        {
          title: '',
          description: `L’agitatore può essere azionato con motore
pneumatico ad aria compressa o con motore elettrico
trifase/monofase. La soluzione con motore pneumatico
permette di avere velocità variabile ed è adatto per
funzionamento in zona classificata ATEX a rischio di
esplosione.`
        },
        {
          description: `La soluzione con motore elettrico prevede un quadro
interruttore locale con fungo di emergenza e
salvamotore magnetotermico tripolare con autoritenuta
(la ripartenza non è automatica cessato il problema), o
inverter con azionamento locale tramite tastierino e
potenziometro per regolare la velocità (20÷50Hz
configurazione standard di fabbrica).`
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: 'Technical Sheet'
      },
      seriesDetails: [ 
        {
          title: '',
          description: `GREC has standardized a range of agitators for 200 and 1000 liter containers. The agitators are portable and easily handled by the user or with the help of lifting equipment such as forklifts or hoists. They can be attached to the container or external structures and are designed for various applications.`
        },
        {
          title: '',
          description: `The agitator can be powered by a compressed air pneumatic motor or an electric motor (single-phase/three-phase). The pneumatic motor option allows for variable speed and is suitable for operation in ATEX-classified explosive risk zones.`
        },
        {
          description: `The electric motor option includes a local control panel with an emergency stop button and a three-pole thermal magnetic motor protection switch with self-hold (the motor will not restart automatically after resolving the issue) or an inverter with local control via a keypad and a potentiometer to adjust speed (standard factory configuration: 20-50Hz).`
        }
    ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "AGITATORI PORTATILI",
    description2: "PORTATILI PER INDUSTRIA CHIMICA",
    description3: `Utilizzati per piccoli fusti e serbatoi fino a 1500 litri, Particolarmente usati nell’industria delle vernici e della chimica.
Accessori disponibili: Morsetti e travi di supporto per tank IBC, interruttori, motori ad aria.`,
    description1Eng: "PORTABLE AGITATORS",
    description2Eng: "PORTABLE FOR CHEMICAL INDUSTRY",
    description3Eng: `Used for small drums and tanks up to 1500 liters, particularly used in the paint and chemical industries. Accessories available: Clamps and support beams for IBC tanks, switches, air motors.`

  },



  {
    id: 13,
    route: 'TM',
    category: 'IN LINEA',
    categoryEng : 'IN-LINE',
    img: `/card/tm.png`,
    img2: `/card/tm1.png`,
    schedatecnica: '/schede_tecniche/miscelatori-statici.pdf',
    link: "https://sketchfab.com/models/5a56f0173cc4440483d9ce6ae990c75a/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "TM",
      imgDetails: '',
    details: {
      header: {
        title: 'TM',
        subtitle: '',
        description: ``,
        buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: 'TM',
        subtitle: '',
        description: ``,
        buttonLabel: 'Technical Sheet'
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: 'OLI & GAS',
        items: ['Miscelazione in linea di diversi tagli di prodotto']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparatori in linea']
      },

    ],
    applicationEng: [
      {
        group: 'OIL & GAS',
        items: ['In-line Mixing of Different Product Grades']
      },
      {
        group: 'PAINT INDUSTRY',
        items: ['Inline mixers']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI STATICI",
    description3: `Elementi a basso costo per regime turbolento.`,
    description1Eng: "IN-LINE",
    description2Eng: "STATIC MIXERS",
    description3Eng: "Low-cost elements for turbulent flow."

  },

  {
    id: 14,
    route: 'BM',
    category: 'IN LINEA',
    categoryEng : 'IN-LINE',
    img: `/card/bm.png`,
    img2: `/card/bm1.png`,
    schedatecnica: '/schede_tecniche/miscelatori-statici.pdf',
    link: "",
    title: "BM",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: 'Technical Sheet'
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: 'OLI & GAS',
        items: ['Miscelazione in linea di diversi tagli di prodotto']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparatori in linea']
      },

    ],
    applicationEng: [
      {
        group: 'OIL & GAS',
        items: ['In-line Mixing of Different Product Grades']
      },
      {
        group: 'PAINT INDUSTRY',
        items: ['Inline mixers']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI STATICI",
    description3: `Elementi per applicazione gas/liquido o regime transizionale.`,
    description1Eng: "IN-LINE",
    description2Eng: "STATIC MIXERS",
    description3Eng: "Elements for gas/liquid application or transitional flow."

  },

  {
    id: 15,
    route: 'VM',
    category: 'IN LINEA',
    categoryEng : 'IN-LINE',
    img: `/card/vm.png`,
    img2: `/card/vm1.png`,
    schedatecnica: '/schede_tecniche/miscelatori-statici.pdf',
    link: "",
    title: "VM",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: 'Technical Sheet'
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: 'OLI & GAS',
        items: ['Miscelazione in linea di diversi tagli di prodotto']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparatori in linea']
      },

    ],
    applicationEng: [
      {
        group: 'OIL & GAS',
        items: ['In-line Mixing of Different Product Grades']
      },
      {
        group: 'PAINT INDUSTRY',
        items: ['Inline mixers']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI STATICI",
    description3: `Elementi ad alto sforzo di taglio per regime laminare.`,
    description1Eng: "IN-LINE",
    description2Eng: "STATIC MIXERS",
    description3Eng: "Elements with high shear stress for laminar flow."

  },

  {
    id: 16,
    route: 'HY',
    category: 'IN LINEA',
    categoryEng : 'IN-LINE',
    img: `/card/hy.png`,
    img2: `/card/hy1.png`,
    schedatecnica: '/schede_tecniche/miscelatori-statici.pdf',
    link: "",
    title: "HY",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: 'Scheda Tecnica'
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: 'Technical Sheet'
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: 'OLI & GAS',
        items: ['Miscelazione in linea di diversi tagli di prodotto']
      },
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparatori in linea']
      },

    ],
    applicationEng: [
      {
        group: 'OIL & GAS',
        items: ['In-line Mixing of Different Product Grades']
      },
      {
        group: 'PAINT INDUSTRY',
        items: ['Inline mixers']
      },

    ],
    description1: "IN LINEA",
    description2: "MISCELATORI STATICI",
    description3: `Elementi elicoidali per applicazioni alimentari e bevande.`,
    description1Eng: "IN-LINE",
    description2Eng: "STATIC MIXERS",
    description3Eng: "Helical elements for FOOD INDUSTRY applications."

  },

  // {
  //   id: 17,
  //   route: '',
  //   category: 'IN LINEA',
  //   categoryEng : 'IN-LINE',
  //   img: ``,
  //   link: "",
  //   title: "",
  //     imgDetails: '',
  //   details: {
  //     header: {
  //       title: '',
  //       subtitle: '',
  //       description: ``,
  //       buttonLabel: ''
  //     },
  //     seriesDetails: [
  //       {
  //         title: '',
  //         description: ''
  //       },
  //       {
  //         title: '',
  //         description: ''
  //       }
  //     ]
  //   },
  //   application: [
  //     {
  //       group: '',
  //       items: ['']
  //     },

  //   ],
  //   description1: "IN LINEA",
  //   description2: "MISCELATORI DINAMICI",
  //   description3: ``
    
  // },


  {
    id: 18,
    route: 'IN-LINEA',
    category: 'IN LINEA',
    categoryEng : 'IN-LINE',
    img: `/card/guth-1.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/4c7c0bfcb1174b129f94b2ea0c65c0b6/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "IN-LINEA",
      imgDetails: '/images/18-427853.png',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: 'INDUSTRIA DELLE VERNICI',
        items: ['Preparatori in linea']
      },
        {
          group: 'OLI & GAS',
          items: ['Miscelazione in linea di diversi tagli di prodotto']
        }


    ],
    applicationEng: [
      {
        group: 'PAINT INDUSTRY',
        items: ['Inline mixers']
      },
        {
          group: 'OIL & GAS',
          items: ['In-line Mixing of Different Product Grades']
        }
  
    ],
    description1: "IN LINEA",
    description2: "MISCELATORI DINAMICI",
    description3: `Sistema di miscelazione in linea a tre stadi ad alta azione di taglio per prodotti con alta differenza di viscosità Il miscelatore statico (accessorio) serve ad omogeneizzare le densità.`,
    description1Eng: "INLINE",
    description2Eng: "DYNAMIC MIXERS",
    description3Eng: `Three-stage inline mixing system with high shear action for products with a high viscosity difference. The static mixer (accessory) is used to homogenize densities.`

  },



  {
    id: 19,
    route: 'T',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/t.jpeg`,
    link: "https://sketchfab.com/models/b52a07952b2946c584284a8ae5362531/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "T",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PORTATA",
    description3: `A profilo sottile, idraulicamente ottimizzato, efficienza molto elevata.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH FLOW",
    description3Eng: "Slim profile, hydraulically optimized, very high efficiency."

  },

  {
    id: 20,
    route: 'B',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/b.jpeg`,
    link: "https://sketchfab.com/models/fb9470019100423fac918c1c38f5f097/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "B",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PORTATA",
    description3: `Bipala pieghevole, per agitatori portatili.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH FLOW",
    description3Eng: "Foldable two-blade, for portable agitators."


  },

  {
    id: 21,
    route: 'F',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/f.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/61d0b68d10164e8997c4f2d812c225d8/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "F",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PORTATA",
    description3: `Tripala a profilo alare, alta efficienza.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH FLOW RATE",
    description3Eng: `Three-blade airfoil design, high efficiency.`

  },

  {
    id: 21.1,
    route: 'L',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/l.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/b4af3c61cd454362ad1493b79ca2c83e/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "L",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PORTATA",
    description3: `Tripala a profilo alare, alta efficienza, bi-direzionale`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH FLOW RATE",
    description3Eng: `Tripala a profilo alare, alta efficienza, bi-direzionale`

  },



  {
    id: 22,
    route: 'M',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `https://media.sketchfab.com/models/68012e2f5ade416fbf368a55b8abef74/thumbnails/029ac91f64944edeab57a0bde11dbeb4/c79919f929b14b8897851bd496ac5755.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/68012e2f5ade416fbf368a55b8abef74/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "M",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PREVALENZA",
    description3: `Elica marina da fusione, alta efficienza.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH HEAD",
    description3Eng: `Marine cast propeller, high efficiency.`

  },


  {
    id: 23,
    route: 'G',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/g.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/c5e8957d0f6340ba8a8fce061d8345c6/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "G",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTA PREVALENZA",
    description3: `Pala a profilo largo da piega, per alta viscosità o prodotti delicati.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH HEAD",
    description3Eng: `Wide blade folding propeller, for high viscosity or delicate products.`

  },

  // {
  //   id: 24,
  //   route: 'C',
  //   category: 'GIRANTI',
  //   categoryEng : 'IMPELLERS',
  //   img: ``,
  //   link: "",
  //   title: "C",
  //     imgDetails: '',
  //   details: {
  //     header: {
  //       title: '',
  //       subtitle: '',
  //       description: ``,
  //       buttonLabel: ''
  //     },
  //     seriesDetails: [
  //       {
  //         title: '',
  //         description: ''
  //       },
  //       {
  //         title: '',
  //         description: ''
  //       }
  //     ]
  //   },
  //   application: [
  //     {
  //       group: '',
  //       items: ['']
  //     },

  //   ],
  //   description1: "GIRANTI",
  //   description2: "ALTA PREVALENZA",
  //   description3: `Pala a profilo largo ottimizzato, per alta viscosità o prodotti delicati.`,
  //   description1Eng: "IMPELLERS",
  //   description2Eng: "HIGH HEAD",
  //   description3Eng: "Optimized wide profile blade, for high viscosity or delicate products."

  // },



  // {
  //   id: 25,
  //   route: 'TD',
  //   category: 'GIRANTI',
  //   categoryEng : 'IMPELLERS',
  //   img: ``,
  //   link: "",
  //   title: "TD",
  //     imgDetails: '',
  //   details: {
  //     header: {
  //       title: '',
  //       subtitle: '',
  //       description: ``,
  //       buttonLabel: ''
  //     },
  //     seriesDetails: [
  //       {
  //         title: '',
  //         description: ''
  //       },
  //       {
  //         title: '',
  //         description: ''
  //       }
  //     ]
  //   },
  //   application: [
  //     {
  //       group: '',
  //       items: ['']
  //     },

  //   ],
  //   description1: "GIRANTI",
  //   description2: "IBRIDE",
  //   description3: ``,
  //   description1Eng: "IMPELLERS",
  //   description2Eng: "HYBRID",
  //   description3Eng: ``
  // },

  {
    id: 26,
    route: 'E',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/e.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/0e494323fc9a4644b9b4651b6849558d/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "E",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "IBRIDE",
    description3: `Turbina assiale per dissoluzione e miscelazioni a media viscosità.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HYBRID",
    description3Eng: `Axial turbine for dissolution and mixing of medium viscosity.`

  },

  {
    id: 27,
    route: 'P',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/p.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/40cc6330ef534e20b5aedac8d86d09dd/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "P",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "IBRIDE",
    description3: `Turbina assiale per dissoluzione e miscelazioni a media viscosità.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HYBRID",
    description3Eng: `Axial turbine for dissolution and mixing of medium viscosity.`
    
  },

  {
    id: 28,
    route: 'R',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/r.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/372e28469247452fbe3453297122f380/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "R",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTO TAGLIO",
    description3: `Turbina radiale per dispersioni a bassa velocità.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH SHEAR",
    description3Eng: `Radial turbine for low-speed dispersions.`

  },
  {
    id: 29,
    route: 'H',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/h.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/50fdd7dd1e5b4cae956de8e3200dc132/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "H",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTO TAGLIO",
    description3: `Turbina radiale a disco, tipo Rusthon per dispersioni gas/liquido.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH SHEAR",
    description3Eng: `Radial disk turbine, Rusthon type, for gas/liquid dispersions.`

  },
  {
    id: 30,
    route: 'S',
    category: 'GIRANTI',
    categoryEng : 'IMPELLERS',
    img: `/card/s.jpeg`,
    imgHome: '',
    link: "https://sketchfab.com/models/caca43a37bc54694bc626de035e7c1bb/embed?autostart=1&camera=0&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_hint=1&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&watermark=0",
    title: "S",
      imgDetails: '',
    details: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    detailsEng: {
      header: {
        title: '',
        subtitle: '',
        description: ``,
        buttonLabel: ''
      },
      seriesDetails: [
        {
          title: '',
          description: ''
        },
        {
          title: '',
          description: ''
        }
      ]
    },
    application: [
      {
        group: '',
        items: ['']
      },

    ],
    applicationEng: [
      {
        group: '',
        items: ['']
      },

    ],
    description1: "GIRANTI",
    description2: "ALTO TAGLIO",
    description3: `A dente di sega per dispersioni difficili.`,
    description1Eng: "IMPELLERS",
    description2Eng: "HIGH SHEAR",
    description3Eng: `Serrated blade for difficult dispersions.`

  },
];


export default data;