import React, { useEffect } from 'react'

const StaticConsoleLog = () => {
  useEffect(() => {
    const logo = `

    ▄█▄    █▄▄▄▄ ▄███▄   ██      ▄▄▄▄▀ ▄███▄   ██▄       ███   ▀▄    ▄ 
    █▀ ▀▄  █  ▄▀ █▀   ▀  █ █  ▀▀▀ █    █▀   ▀  █  █      █  █    █  █  
    █   ▀  █▀▀▌  ██▄▄    █▄▄█     █    ██▄▄    █   █     █ ▀ ▄    ▀█   
    █▄  ▄▀ █  █  █▄   ▄▀ █  █    █     █▄   ▄▀ █  █      █  ▄▀    █    
    ▀███▀    █   ▀███▀      █   ▀      ▀███▀   ███▀      ███    ▄▀                                                                                                        
   ▄████████  ▄█     ▄████████    ▄████████  ▄█     █▄     ▄████████  ▄█        ▄█       
  ███    ███ ███    ███    ███   ███    ███ ███     ███   ███    ███ ███       ███       
  ███    █▀  ███▌   ███    ███   ███    █▀  ███     ███   ███    ███ ███       ███       
 ▄███▄▄▄     ███▌  ▄███▄▄▄▄██▀  ▄███▄▄▄     ███     ███   ███    ███ ███       ███       
▀▀███▀▀▀     ███▌ ▀▀███▀▀▀▀▀   ▀▀███▀▀▀     ███     ███ ▀███████████ ███       ███       
  ███        ███  ▀███████████   ███    █▄  ███     ███   ███    ███ ███       ███       
  ███        ███    ███    ███   ███    ███ ███ ▄█▄ ███   ███    ███ ███▌    ▄ ███▌    ▄ 
  ███        █▀     ███    ███   ██████████  ▀███▀███▀    ███    █▀  █████▄▄██ █████▄▄██ 
                    ███    ███                                       ▀         ▀         
                          
          █ █ █ ██  ██████████  WEB & DESIGN DIVISION  ██████████  ██ █ █ █
                    `

    const gradientCss = `
      background: linear-gradient(
        90deg,
        #FF0000 0%,
        #FF3333 25%,
        #FF6666 50%,
        #FF9999 75%,
        #FFCCCC 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    `

    // Stampa il logo una sola volta con il gradiente rosso
    console.log('%c' + logo, gradientCss)
  }, [])

  return null
}

export default StaticConsoleLog