import React, { useEffect, useState,} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
  IoConstructOutline,
  IoFlaskOutline,
  IoFlashOutline,
  IoMedkitOutline,
  IoWaterOutline,
  IoWater,
  IoColorPaletteOutline,
  IoClose,
} from "react-icons/io5";

import { BiDrink } from "react-icons/bi";
import { ArrowUpRight } from 'lucide-react';
import { motion, useAnimation , AnimatePresence  } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import data from "../../noSQL/data"
import { useTranslation } from 'react-i18next';


function ApplicazioniHome() {
    const { t } = useTranslation();
    const location = useLocation();
    const isHomepage = location.pathname === '/';

    const sectors = [
      {
        id: 'alimenti-bevande',
        title: t('applications.alimentareTitle'),
        href: '',
        img: '/applications/1.png',
        icon: <BiDrink size={45} />,
        details: {
          heading: t('applications.alimentareTitle'),
          info: [
            {
              title: t('applications.alimentareSector1'),
              links: ['ALF', 'ALS', 'AL'],
            },
            {
              title: t('applications.alimentareSector2'),
              links: ['ALC'],
            },
            {
              title: t('applications.alimentareSector3'),
              links: ['AGITATORI VERTICALI'],
            },
            {
              title: t('applications.alimentareSector4'),
              links: ['AV', 'AL'],
            },
            {
              title: t('applications.alimentareSector5'),
              links: ['TD', 'COWLES'],
            },
          ],
        },
      },
      {
        id: 'miniera-ceramica',
        title: t('applications.ceramicheTitle'),
        href: '',
        img: '/applications/ceramica.jpg',
        icon: <IoConstructOutline size={45} />,
    
        details: {
          heading: t('applications.ceramicheTitle'),
          info: [
            {
              title: t('applications.ceramicheSector1'),
              links: ['AVS', 'AVL']
            },
            {
              title: t('applications.ceramicheSector2'),
              links: ['AVS', 'AVSh', 'AVL']
            },
            {
              title: t('applications.ceramicheSector3'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.ceramicheSector4'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.ceramicheSector5'),
              links: ['AGITATORI VERTICALI']
            }
          ]
        }
      },
      {
        id: 'chimica',
        title: t('applications.chimicheTitle'),
        href: '',
        img: '/applications/chimica.jpg',
        icon: <IoFlaskOutline size={45} />,
        details: {
          heading: t('applications.chimicheTitle'),
          info: [
            {
              title: t('applications.chimicheSector1'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.chimicheSector2'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.chimicheSector3'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.chimicheSector4'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.chimicheSector5'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.chimicheSector6'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.chimicheSector7'),
              links: ['In funzione della geometria del serbatoio']
            },
            {
              title: t('applications.chimicheSector8'),
              links: ['In funzione della geometria del serbatoio']
            }
          ]
        }
      },
      {
        id: 'energia',
        title: t('applications.energiaTitle'),
        href: '',
        img: '/applications/energia.jpg',
        icon: <IoFlashOutline size={45} />,
        details: {
          heading: t('applications.energiaTitle'),
          info: [
            {
              title: t('applications.energiaSector1'),
              links: ['AVS', 'ALC']
            },
            {
              title: t('applications.energiaSector2'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.energiaSector3'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.energiaSector4'),
              links: ['ALC']
            }
          ]
        }
      },
      {
        id: 'farmaceutica',
        title: t('applications.farmaTitle'),
        href: '',
        img: '/applications/farmacia.jpg',
        icon: <IoMedkitOutline size={45} />,
        details: {
          heading: t('applications.farmaTitle'),
          info: [
            {
              title: t('applications.farmaSector1'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.farmaSector2'),
              links: ['AGITATORI VERTICALI E MAGNETICI']
            },
            {
              title: t('applications.farmaSector3'),
              links: ['AGITATORI VERTICALI', 'TURBODISPERSORI']
            }
          ]
        } 
      },
      {
    
        id: 'oli-gas',
        title: t('applications.oliTitle'),
        href: '',
        img: '/applications/petrolio.jpg',
        icon: <IoWater size={45} />,
        details: {
          heading:  t('applications.oliTitle'),
          info: [
            {
              title: t('applications.oliSector1'),
              links: ['ALC']
            },
            {
              title: t('applications.oliSector2'),
              links: ['ALC']
            },
            {
              title: t('applications.oliSector3'),
              links: ['AGITATORI DINAMICI IN LINEA', 'MISCELATORI STATICI']
            }
          ]
        }
      },
      {
        id: 'acque',
        title:  t('applications.waterTitle'),
        href: '',
        img: '/applications/acque.jpg',
        icon: <IoWaterOutline size={45} />,
        details: {
          heading: t('applications.waterTitle'),
          info: [
            {
              title: t('applications.waterSector1'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.waterSector2'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.waterSector3'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.waterSector4'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.waterSector5'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.waterSector6'),
              links: ['AGITATORI VERTICALI']
            }
          ]
        }
      },
      {
        id: 'vernici',
        title: t('applications.verniciTitle'),
        href: '',
        img: '/applications/vernice.jpg',
        icon: <IoColorPaletteOutline size={45} />,
        details: {
          heading: t('applications.verniciTitle'),
          info: [
            {
              title: t('applications.verniciSector1'),
              links: ['AGITATORI VERTICALI']
            },
            {
              title: t('applications.verniciSector2'),
              links: ['DISPERSORI COWLES']
            },
            {
              title: t('applications.verniciSector3'),
              links: ['MISCELATORI IN LINEA']
            }
          ]
        }
      }
    ];
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedSector, setSelectedSector] = useState(null);
    const controls = useAnimation();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
  
    const openModal = (sector) => {
      setSelectedSector(sector);
      setModalIsOpen(true);
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
      setSelectedSector(null);
    };
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);
  
    const containerVariants = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.1
        }
      }
    };
  
    const itemVariants = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5
        }
      }
    };
  
    const modalVariants = {
      hidden: { opacity: 0, scale: 0.8 },
      visible: { 
        opacity: 1, 
        scale: 1,
        transition: {
          duration: 0.3
        }
      },
      exit: { 
        opacity: 0, 
        scale: 0.8,
        transition: {
          duration: 0.3
        }
      }
    };
    const generateDetailsComponent = (details) => (
      <div className='sector-detail'>
        <h2>{details.heading}</h2>
        <motion.div 
          className='sector-detail-info'
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {details.info.map((item, index) => (
            <motion.div key={index} variants={itemVariants}>
              <p className='p-sector-detail'>
                <span>{item.title}</span>
                <span>
                  {item.links.map((link, idx) => {
                    const toUrlFriendly = (name) => name.toLowerCase().replace(/ /g, '-');
                    const product = data.find(p => p.title.toUpperCase() === link.toUpperCase());
                    if (product) {
                      return (
                        <Link key={idx} to={`/prodotti/${product.route}`} onClick={closeModal}>
                          {link}
                        </Link>
                      );
                    } else {
                      const category = ['AGITATORI VERTICALI', 'AGITATORI LATERALI', 'AGITATORI PORTATILI', 'IN LINEA', 'GIRANTI']
                        .find(cat => cat.toUpperCase() === link.toUpperCase());
                      
                      if (category) {
                        return (
                          <Link key={idx} to={`/categoria/${toUrlFriendly(category)}`} onClick={closeModal}>
                          {currentLanguage === 'en' && link === 'AGITATORI VERTICALI' ? 'VERTICAL AGITATORS' :
                            currentLanguage === 'en' && link === 'AGITATORI LATERALI' ? 'SIDE AGITATORS' : link}                        
                          </Link>
                        );
                      } else {
                        return <a key={idx}>{link}</a>;
                      }
                    }
                  })}
                </span>
              </p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    );
  
    return (
      <>
        <section className=''>
          <motion.div 
            className="sector-list"
            ref={ref}
            variants={containerVariants}
            initial="hidden"
            animate={controls}
          >
            <div className="sector-container with-absolute-title">
              {isHomepage && ( 
                <h3 className='absolute-title'>
                  <Link to="/applicazioni" className="link-homepage">
                    <span>
                      {currentLanguage === 'en' 
                        ? 'Fields of Application' 
                        : 'Campi d\'Applicazione'}
                    </span> 
                    <ArrowUpRight />
                  </Link>
                </h3>
              )}              
              {sectors.map((sector, index) => (
                <motion.div
                  key={index}
                  className="sector-item"
                  onClick={() => openModal(sector)}
                  variants={itemVariants}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="icon-container">{sector.icon}</div>
                  <p>{sector.title}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
          <AnimatePresence>
            {modalIsOpen && (
              <motion.div 
                className="modal-application"
                variants={modalVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <motion.div className="modal-content-application">
                  <motion.button 
                    onClick={closeModal} 
                    className="close-button-application"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <IoClose />
                  </motion.button>
                  {selectedSector && (
                    <>
                      <div>
                        <motion.div 
                          className="image-container"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <img src={selectedSector.img} alt={selectedSector.title} className="modal-img" />
                          <div className="image-gradient"></div>
                        </motion.div>
                        {generateDetailsComponent(selectedSector.details)}
                      </div>
                    </>
                  )}
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </section>
      </>
    );
  }
  

  export default ApplicazioniHome