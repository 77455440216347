import React, { useEffect, useState, useRef, useCallback,  useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ApplicazioniHome from "./components/ApplicazioniHome"

// // // // // // // // // // // // // // // // // // // // // // // // 
// Applicazioni
// // // // // // // // // // // // // // // // // // // // // // // // 

function Applicazioni() {

    const { t } = useTranslation();
  
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
  
  
    return (
      <>
        <section className='page--container' >
          <div className='Grec-title-container'>
          <h1>{currentLanguage === 'en' ? 'Applications' : 'Applicazioni'}</h1>
            <p>
            {currentLanguage === 'en' ? 'Discover our solutions for different industrial sectors' : 'Scopri le nostre soluzioni per diversi settori industriali.'}
            </p>
          </div>
          <ApplicazioniHome/>
          <br /><br />
  
  
        </section>
      </>
    );
  }

export default Applicazioni