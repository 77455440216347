import React, { useState } from 'react';
import { BrowserRouter as Router, Link, useLocation} from 'react-router-dom';
import {  House } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function BottomNavbar() {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [isProductsHovered, setIsProductsHovered] = useState(false);
    const currentLanguage = i18n.language;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    let categories={}
  
  if(currentLanguage==="it"){
     categories = {
      'agitatori-verticali': 'AGITATORI VERTICALI',
      'agitatori-laterali': 'AGITATORI LATERALI',
      'agitatori-portatili': 'AGITATORI PORTATILI',
      'in-linea': 'IN LINEA',
      'giranti': 'GIRANTI'
    };
  }
  
  else{
    categories = {
      'agitatori-verticali': 'VERTICAL AGITATORS',
      'agitatori-laterali': 'SIDE AGITATORS',
      'agitatori-portatili': 'PORTABLE AGITATORS',
      'in-linea': 'IN-LINE',
      'giranti': 'IMPELLERS'
    };
  }
    return (
      <nav className="Grec--navbar-desktop-bottom">
        <Link to="/">
          <img src="/logo-red.png" alt="Grec logo" />
        </Link>
        <Link to="/" className={location.pathname === '/' ? 'Active' : ''}>
          <House />
        </Link>
        <Link to="/chi-siamo" className={location.pathname === '/chi-siamo' ? 'Active' : ''}>
          <p>{t('nav.aboutUs')}</p>
        </Link>
        <div 
          className={`products-dropdown ${location.pathname.startsWith('/prodotti') ? 'Active' : ''}`}
          onMouseEnter={() => setIsProductsHovered(true)}
          onMouseLeave={() => setIsProductsHovered(false)}
        >
          <Link to="/prodotti">
            <p>{t('nav.products')}</p>
          </Link>
          {isProductsHovered && (
            <div className="dropdown-content">
              {Object.entries(categories).map(([route, name]) => (
                <Link key={route} to={`/categoria/${route}`}>
                  {name}
                </Link>
              ))}
            </div>
          )}
        </div>
        <Link to="/download" className={location.pathname.startsWith('/download') ? 'Active' : ''}>
          <p>{t('nav.download')}</p>
        </Link>
        <Link to="/applicazioni" className={location.pathname.startsWith('/applicazioni') ? 'Active' : ''}>
          <p>{t('nav.applications')}</p>
        </Link>
        <Link to="/gallery" className={location.pathname.startsWith('/gallery') ? 'Active' : ''}>
          <p>{t('nav.gallery')}</p>
        </Link>
        <Link to="/contatti" className={location.pathname.startsWith('/contatti') ? 'Active' : ''}>
          <p>{t('nav.contacts')}</p>
        </Link>
        <Link id="last-a" to="/lavora-con-noi" className={location.pathname.startsWith('/lavora-con-noi') ? 'Active' : ''}>
          <p>{t('nav.workWithUs')}</p>
        </Link>
        <img
          style={{ height: '30px', marginRight: '5px', marginBottom: '5px', cursor: 'pointer' }}
          src="/icons/italy-circular.svg"
          alt="ita-flags"
          onClick={() => changeLanguage('it')}
        />
        <img
          style={{ height: '30px', marginRight: '15px', marginBottom: '5px', cursor: 'pointer' }}
          src="/icons/great-britain-circular.svg"
          alt="eng-flags"
          onClick={() => changeLanguage('en')}
        />
      </nav>
    );
  }

  export default BottomNavbar