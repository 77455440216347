import React, { useEffect, useState, useRef, useCallback,  useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  IoLogoFacebook,
  IoLogoLinkedin,
} from "react-icons/io5";

import { ArrowRight, ChevronRight , Rotate3D , House , Phone, Printer, Mail  } from 'lucide-react';

import "@maptiler/sdk/dist/maptiler-sdk.css";




import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    const [clickCount, setClickCount] = useState(0);
    const [easterEggActivated, setEasterEggActivated] = useState(false);
  
    useEffect(() => {
      if (clickCount === 5) {
        setEasterEggActivated(true);
        window.location.hash = 'createdbyVITALIPISANI';
      }
    }, [clickCount]);
  
    const handleFirewallClick = () => {
      setClickCount(prevCount => prevCount + 1);
    };
  
    return (
      <section className='Grec--footer'>
        <div className='footer-main'>
          <div className='footer-logo-container'>
            <Link to="/">
              <img src="/logo-red.png" alt="GREC Logo" />
            </Link>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/grec-srl/"><IoLogoLinkedin size={35} /></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/grecsrl"><IoLogoFacebook size={35} /></a>
            </div>
          </div>
          <div  className='body-links' style={{ textTransform: 'uppercase' }}>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/"><p>{t('nav.home')}</p></Link>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/chi-siamo"><p>{t('nav.aboutUs')}</p></Link>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/prodotti"><p>{t('nav.products')}</p></Link>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/download"><p>{t('nav.download')}</p></Link>
          </div>
          <div className='body-links'  style={{ textTransform: 'uppercase' }}>
            <Link style={{  color: 'white', textDecoration : 'none' }}  to="/applicazioni"><p>{t('nav.applications')}</p></Link>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/gallery"><p>{t('nav.gallery')}</p></Link>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/contatti"><p>{t('nav.contacts')}</p></Link>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/lavora-con-noi"><p>{t('nav.workWithUs')}</p></Link>
          </div>
          <div>
            <Link  style={{  color: 'white', textDecoration : 'none' }} to="/social-policy"><p>{t('footer.socialPolicy')}</p></Link>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/cookie-policy"><p>{t('footer.cookiePolicy')}</p></Link>
            <Link style={{  color: 'white', textDecoration : 'none' }} to="/privacy-policy"><p>{t('footer.privacyPolicy')}</p></Link>
          </div>
          <div className='footer-interactiv'>
            <a href="tel:+390236645050" className="contact-item">
              <Phone size={28} />
              <span>+39 02 36645050</span>
            </a>
            <a href="mailto:info@grec.it" className="contact-item">
              <Mail size={28} />
              <span>info@grec.it</span>
            </a>
       
          </div>
          <Link to="/certifications">
          <img className='certifications__logo' src="/certifications/en_9001.png" />
          </Link>
        </div>
        <div className='footer-down'>
          <div className='footer-info-legal'>
            <p>GREC Srl - Viale Lombardia 16/N - 20081 Cassinetta di Lugagnano (MI) - ITALY</p>
            <p>C.F. / P.IVA / Registro impr.di Milano 06972530965, R.E.A. di Milano n° 1926397, Cap.Soc.: Euro 90.000,00 i.v.</p>
          </div>
          <hr style={{ opacity: 0.2, margin: '5px' }} />
          <div className='flex'>
            <p>Copyright © 2024 GREC. All Rights Reserved.</p>
            <p className='nonclic'  ><span className='ShneBreit' onClick={handleFirewallClick}>
              {easterEggActivated ? 'Created by VITALI PISANI' : 'Created by Firewall S.r.l.'}
            </span></p>
          </div>
        </div>
      </section>
    );
  }

export default Footer;