import React, { useEffect, useState, useMemo, useRef } from 'react';
import { BrowserRouter as Router, Link, useParams, useNavigate } from 'react-router-dom';
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { PiMouseLeftClickFill, PiMouseRightClickFill, PiMouseScroll } from "react-icons/pi";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import data from "../noSQL/data";
import { useTranslation } from 'react-i18next';
import GirantiTable from "./components/GirantiTable";
import QuoteModal from '../components/QuoteModal';


const ProductDetails = () => {
  const { route } = useParams();
  const navigate = useNavigate();
  const controls = useAnimation();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ref, inView] = useInView({ triggerOnce: true });
  const machineMainRef = useRef(null);

  const productIndex = data.findIndex(item => item.route === route);
  const product = data[productIndex];

  const specialProducts = ['TM', 'BM', 'VM', 'HY'];

  const isTMProduct = product && product.title === 'TM';
  const isSpecialProduct = product ? specialProducts.includes(product.title) : false;

  const staticMixers = useMemo(() => 
    data.filter(item => item.description2 === "MISCELATORI STATICI"),
    []
  );

  const sameCategoryProducts = useMemo(() => 
    product ? data.filter(item => item.category === product.category && item.route !== route) : [],
    [product, route]
  );

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    const machineMain = machineMainRef.current;
    if (!machineMain) return;

    const moveCursor = (e) => {
      const rect = machineMain.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      machineMain.style.setProperty('--cursor-x', `${x}px`);
      machineMain.style.setProperty('--cursor-y', `${y}px`);
    };

    const handleMouseEnter = () => document.body.classList.add('custom-cursor-active');
    const handleMouseLeave = () => document.body.classList.remove('custom-cursor-active');

    machineMain.addEventListener('mousemove', moveCursor);
    machineMain.addEventListener('mouseenter', handleMouseEnter);
    machineMain.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      machineMain.removeEventListener('mousemove', moveCursor);
      machineMain.removeEventListener('mouseenter', handleMouseEnter);
      machineMain.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  if (productIndex === -1) {
    return <h2>Prodotto non trovato</h2>;
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleBackClick = () => {
    const prevIndex = productIndex > 0 ? productIndex - 1 : data.length - 1;
    navigate(`/prodotti/${data[prevIndex].route}`);
  };

  const handleForwardClick = () => {
    const nextIndex = productIndex < data.length - 1 ? productIndex + 1 : 0;
    navigate(`/prodotti/${data[nextIndex].route}`);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        type: 'spring',
        stiffness: 50,
      },
    }),
  };
  
  const isStaticMixer = product && product.description2 === "MISCELATORI STATICI";

  return (
    <>
      <section className='Product-Body'>
        <br />  
        <div className='Grec--machine--header'>
          <h2>
            <Link to="/prodotti">PRODOTTI</Link> / {product.category} / {product.title}
          </h2>
          <button onClick={handleOpenModal}>
            {currentLanguage === 'en' ? 'Request a quote' : 'Richiedi un preventivo'}
          </button>
          <QuoteModal isOpen={isModalOpen} handleClose={handleCloseModal} productTitle={product.title} />
        </div>
        <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />
      
        <div className='Grec--machine--main' ref={machineMainRef}>
          <div className='Grec--machine--info'>
            <h1>{product.title}</h1>
            <p>{currentLanguage === 'it' ? product.description1 : product.description1Eng}</p>
            <p>{currentLanguage === 'it' ? product.description2 : product.description2Eng}</p>
            <p className='Grec--machine--description' dangerouslySetInnerHTML={{ __html: currentLanguage === 'it' ? product.description3 : product.description3Eng }}></p>
          </div>
          
          <div className='Grec--machine-control' onClick={handleBackClick}>
            <IoArrowBackOutline className='Grec--machine-icon' />
          </div>
          <div className='Grec--machine-control' onClick={handleForwardClick}>
            <IoArrowForwardOutline className='Grec--machine-icon' />
          </div>



          {isTMProduct ? (
            <div className="sketchfab-embed-wrapper rotating">
              <div className='ifram-dynamic-info-bar'>
                <span>
                  <PiMouseLeftClickFill size="30" fill="white" />  
                  <p>{currentLanguage === 'it' ? "Ruota" : "Rotate"}</p>
                </span>
                <span>
                  <PiMouseScroll size="30" fill="white" /> 
                  <p>Zoom</p>
                </span>
                <span>
                  <PiMouseRightClickFill size="30" fill="white" />
                  <p>{currentLanguage === 'it' ? "Muovi" : "Move"}</p>
                </span>
              </div>
              <div id="overlay">
                <iframe
                  title={product.title}
                  src={product.link}
                  frameBorder="0"
                  allowFullScreen
                  style={{ width: '100%', height: '680px' }}
                ></iframe>
              </div>
            </div>
          ) : isStaticMixer ? (
            <div className="static-mixer-image-container">
              <img src={product.img2} alt={product.title} />
            </div>
          ) : (
            <div className="sketchfab-embed-wrapper rotating">
            <div className='ifram-dynamic-info-bar'>
              <span>
                <PiMouseLeftClickFill size="30" fill="white" />  
                <p>{currentLanguage === 'it' ? "Ruota" : "Rotate"}</p>
                </span>
              <span>
                <PiMouseScroll size="30" fill="white" /> 
                <p>Zoom</p>
              </span>
              <span>
                <PiMouseRightClickFill size="30" fill="white" />
                <p>{currentLanguage === 'it' ? "Muovi" : "Move"}</p>
                </span>
            </div>
            <div id="overlay">
              <iframe
                title={product.title}
                src={product.link}
                frameBorder="0"
                allowFullScreen
                style={{ width: '100%', height: '680px' }}
              ></iframe>
            </div>
          </div>
          )}


      
        </div>

        {isSpecialProduct ? (
          <>
            <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />
            <div className="special-product-section">
            <p>
              {currentLanguage === 'en' 
                ? <>
                    The static mixer is the <strong>simplest and most economical device</strong> to achieve <u>an intimate mixing of two or more components</u>. This is accomplished by diverting the fluid streams that pass through it. The main applications are in the fields of <strong>water treatment</strong>, <strong>painting plants</strong>, <strong>food processing</strong>, and <strong>fume/gas scrubbing</strong>. Performance depends on the ratio between flow rates, viscosities, and fluid densities; based on this data and experience across many sectors, <strong>GREC</strong> selects different types and configurations of elements. TM series models, for common applications, are available with <u>1-week delivery</u> in stainless steel with NPT threaded connections.
                  </>
                : <>
                    Il miscelatore statico è il <strong>dispositivo più semplice ed economico</strong> per ottenere <u>un intima miscelazione di due o più componenti</u>. Lo scopo si realizza mediante deviazione dei filetti fluidi delle correnti che lo attraversano. Le principali applicazioni sono nel campo del <strong>trattamento acque</strong>, in <strong>impianti di verniciatura</strong>, <strong>alimentari</strong> e di <strong>abbattimento fumi/gas</strong>. Le prestazioni dipendono dal rapporto tra le portate, le viscosità e le densità dei fluidi da miscelare; sulla base di questi dati e dell'esperienza applicativa in molti settori, <strong>GREC</strong> seleziona differenti tipologie e configurazioni di elementi. I modelli serie TM, per applicazioni più comuni, sono disponibili con <u>consegna 1 settimana</u> in acciaio inossidabile con connessioni filetto NPT.
                  </>
              }
            </p>
            {currentLanguage === "it" && product.schedatecnica && (
              <button className='buttonz'>
                <a href={product.schedatecnica} target="_blank">
                  Scarica Scheda Tecnica
                </a>
              </button>
            )}

            {currentLanguage === "en"  && (
              <button className='buttonz'>
                <a href={product.schedatecnica} target="_blank">
                Download Technical Sheet
                </a>
              </button>
            )}

            {currentLanguage === "en" && product.schedatecnicaEN &&  (
              <button className='buttonz'>
                <a href={product.schedatecnicaEN} target="_blank">
                  Download Technical Sheet
                </a>
              </button>
            )}

              <div className="Grec--card-container">
                {staticMixers.filter(mixer => mixer.route !== route).map((mixer, index) => (
                  <motion.div
                    key={mixer.id}
                    className="Grec--machine-card"
                    initial="hidden"
                    animate="visible"
                    custom={index}
                    variants={cardVariants}
                  >
                    <Link to={`/prodotti/${mixer.route}`}>
                      <div>
                        <div className='card-img-container'>
                          <img src={mixer.img} alt={mixer.title} />
                        </div>
                        <h2>{mixer.title}</h2>
                        <p>{currentLanguage === 'en' ? mixer.description2Eng : mixer.description2}</p>
                        <span>{currentLanguage === 'en' ? mixer.description1Eng : mixer.description1}</span>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
       {product.category !== 'GIRANTI' && (
          <>
            <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />
            <div className="Grec--machine--details-container">
              <div className="Grec--machine--details">
                <img src={product.imgDetails} alt={product.title} />
                <div className='Grec--machine--content'>
                <h2>{(currentLanguage === 'en' ? product.detailsEng.header.title : product.details.header.title)}</h2>
            <h3>{(currentLanguage === 'en' ? product.detailsEng.header.subtitle : product.details.header.subtitle)}</h3>
            <p>{(currentLanguage === 'en' ? product.detailsEng.header.description : product.details.header.description)}</p>
                </div>
                {product.schedatecnica && currentLanguage === 'it' && (
                    <button>
                      <a href={product.schedatecnica} target="_blank">
                        {product.details.header.buttonLabel}
                      </a>
                    </button>
                  )}

                  {product.schedatecnicaEN && currentLanguage === 'en' && (
                    <button>
                      <a href={product.schedatecnicaEN} target="_blank">
                        {product.detailsEng.header.buttonLabel}
                      </a>
                    </button>
                )}
              </div>
  
              {((currentLanguage === 'en' ? product.detailsEng.header2 : product.details.header2) && (
                <div className="Grec--machine--details details-2 ">
                  <div className='Grec--machine--content content-2'>
                  <h2>{(currentLanguage === 'en' ? product.detailsEng.header2.title : product.details.header2.title)}</h2>
              <h3>{(currentLanguage === 'en' ? product.detailsEng.header2.subtitle : product.details.header2.subtitle)}</h3>
              <p>{(currentLanguage === 'en' ? product.detailsEng.header2.description : product.details.header2.description)}</p>
                  </div>
                  {product.schedatecnica2 && currentLanguage === 'it' && (
                  <button>
                    <a href={product.schedatecnica2} target="_blank">
                      {product.details.header2.buttonLabel}
                    </a>
                  </button>
                  )}

                  {product.schedatecnica2EN && currentLanguage === 'en' && (
                    <button>
                      <a href={product.schedatecnica2EN} target="_blank">
                        {product.detailsEng.header2.buttonLabel}
                      </a>
                    </button>
                )}
                </div>
                 ))}
  
    {!product.details.header2 && (currentLanguage === 'en' ? product.detailsEng.seriesDetails : product.details.seriesDetails) && (
            <div className="Grec--machine--series-details">
              {(currentLanguage === 'en' ? product.detailsEng.seriesDetails : product.details.seriesDetails).map((detail, index) => (
                <div className='Grec--machine--series-desc' key={index}>
                  <p><strong>{detail.title} </strong>{detail.description}</p>
                  {detail.button && (
                    <button className='buttonDetailsScheda'  >
                      {detail.buttonLink ? (
                          <a href={detail.buttonLink} target="_blank">{detail.button}</a>
                      ) : (
                        <span>{detail.button}</span>
                      )}
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
            </div>
            <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />
          </>
        )}
          </>
        )}

      

        {product.category !== 'GIRANTI'  && product.application.some(app => app.group && app.items.length > 0) &&
    product.applicationEng.some(app => app.group && app.items.length > 0) && (
          <>
            <div className='machine--body'>
              <h2>{currentLanguage === 'en' ? 'FIELDS OF APPLICATION' : 'CAMPI DI APPLICAZIONE'}</h2>
              <div ref={ref} className="machine--card--aplication--group">
                {(currentLanguage === 'en' ? product.applicationEng : product.application).map((group, index) => (
                  <motion.div
                    className="machine--card--aplication"
                    key={index}
                    initial="hidden"
                    animate={controls}
                    custom={index}
                    variants={cardVariants}
                  >
                    <h3>{group.group}</h3>
                    {group.items.map((item, idx) => (
                      <p key={idx}>{item}</p>
                    ))}
                  </motion.div>
                ))}
              </div>
            </div>
            <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />
          </>
        )}

        {product.category === 'GIRANTI' && (
          <>
            <hr style={{ opacity: 0.2, margin: '25px 8% 25px' }} />
            <div className="Grec--card-container giranti">
              {sameCategoryProducts.map((item, index) => (
                <motion.div
                  key={item.id}
                  className="Grec--machine-card"
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  variants={cardVariants}
                >
                  <Link to={`/prodotti/${item.route}`}>
                    <div>
                      <div className='card-img-container'>
                        <img src={item.img} alt={item.title} />
                      </div>
                      <h2>{item.title}</h2>
                      <p>{currentLanguage === 'en' ? item.description2Eng : item.description2}</p>
                      <span>{currentLanguage === 'en' ? item.description1Eng : item.description1}</span>
                    </div>
                  </Link>
                </motion.div>
              ))}
            </div>
          </>
        )}
        <br/><br/>
      </section>
    </>
  );
};

export default ProductDetails;