import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Product = ({ data }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [hasFiltered, setHasFiltered] = useState(false);
    const cardContainerRef = useRef(null);
  
    const handleFilterClick = (category) => {
      setSelectedCategory((prevCategory) => {
        const newCategory = prevCategory === category ? null : category;
        setHasFiltered(true);
        return newCategory;
      });
    };
  
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const filteredData = selectedCategory
      ? data.filter(item => 
          currentLanguage === 'it'
            ? item.category === selectedCategory 
            : item.categoryEng === selectedCategory 
        )
      : data;
  
    let categoryImages = currentLanguage === "it"
      ? {
          'AGITATORI VERTICALI': '/vito/AGITATORI_VERTICALI.png',
          'AGITATORI LATERALI': '/vito/AGITATORI_LATERALI.png',
          'AGITATORI PORTATILI': '/vito/AGITATORI_PORTATILI.png',
          'IN LINEA': '/vito/AGITATORI_IN-LINEA.png',
          'GIRANTI': '/vito/ELICHE.png'
        }
      : {
          'VERTICAL AGITATORS': '/vito/AGITATORI_VERTICALI.png',
          'SIDE AGITATORS': '/vito/AGITATORI_LATERALI.png',
          'PORTABLE AGITATORS': '/vito/AGITATORI_PORTATILI.png',
          'IN-LINE': '/vito/AGITATORI_IN-LINEA.png',
          'IMPELLERS': '/vito/ELICHE.png'
        };

    const cardVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: (i) => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: i * 0.1,
        },
      }),
    };
  
    useEffect(() => {
      if (hasFiltered && cardContainerRef.current) {
        cardContainerRef.current.scrollIntoView({ 
          behavior: 'smooth', 
          block: 'start'
        });
      }
    }, [selectedCategory, hasFiltered]);
  
    return (
      <>
        <section className='page--container'>
          <div className='Grec-title-container'>
            <h1>{currentLanguage === 'en' ? 'Agitators Mixers Dispersers' : 'Agitatori Miscelatori Dispersori'}</h1>
            <p>
              {currentLanguage === 'en' 
                ? 'Discover our advanced solutions, designed to optimize efficiency in your production processes.' 
                : 'Scopri le nostre soluzioni avanzate, progettate per ottimizzare l\'efficienza nei tuoi processi produttivi.'}
            </p>
          </div>
          <div className='Grec--button-filter'>
            {Object.keys(categoryImages).map(category => (
              <button
                key={category}
                id="vitoz"
                className={selectedCategory === category ? 'active' : ''}
                onClick={() => handleFilterClick(category)}
              >    
                <img 
                  className='icon-category' 
                  src={categoryImages[category]} 
                  alt={`${category} icon`}
                />
                <h3>{category}</h3> 
              </button>
            ))}
          </div>
          <hr style={{ opacity: 0.2, margin: '25px 5% 25px' }} ref={cardContainerRef}/>
       
          <div className="Grec--card-container">
            {filteredData.map((item, index) => (
              <motion.div
                key={item.id}
                className="Grec--machine-card"
                initial="hidden"
                animate="visible"
                custom={index}
                variants={cardVariants}
              >
                <Link to={`/prodotti/${item.route}`}>
                  <div>
                    <div className='card-img-container'>
                      <img src={item.img} alt={item.title} />
                    </div>
                    <h2>{item.title}</h2>
                    <p>{currentLanguage === 'it' ? item.description2 : item.description2Eng}</p>
                    <span>{currentLanguage === 'it' ? item.description1 : item.description1Eng}</span>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </section>
        <br /><br /><br />
      </>
    );
};

export default Product;